import React, { useRef, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"

import * as watchUtils from "@arcgis/core/core/watchUtils"
import Point from "@arcgis/core/geometry/Point"
import * as intl from "@arcgis/core/intl.js"

import {
	view,
	objects,
	bgExpand,
	locateWidget,
	objectRenderer,
	langFields,
} from "../../../utils/plaquesArcgisItems"

import useMediaQuery from "@mui/material/useMediaQuery"

const viewHandles = []

const ObjectMap = (props) => {
	const navigate = useNavigate()
	const { t, i18n } = useTranslation()
	const mapDiv = useRef(null)
	const tooltipDiv = useRef(null)
	const isMobile = useMediaQuery("(min-width:600px)")

	useEffect(() => {
		view.when(() => {
			setTimeout(() => {
				//be timeout neveikia
				intl.setLocale(i18n.language)
			}, 1000)
		})
	}, [])

	useEffect(() => {
		view.container = mapDiv.current
		if (objectRenderer) {
			objects.renderer = objectRenderer
		}

		let pt = new Point({
			x: 583032.6956837717,
			y: 6061494.761669048,
			spatialReference: {
				wkid: 2600,
			},
		})
		view.center = pt
		view.zoom = 3

		viewHandles.forEach((handle) => {
			handle.remove()
		})
		viewHandles.length = 0

		view.whenLayerView(objects).then((objectsView) => {
			watchUtils.whenFalseOnce(objectsView, "updating").then(() => {
				objectsView
					.queryFeatures({
						outFields: langFields[i18n.language],
						where: "",
						returnGeometry: false,
					})
					.then((response) => {
						if (response.features.length) {
							props.setInitialObjectsList(response.features)
							props.setInitialLoading(false)
						}
					})
					.catch((error) => {
						console.error(error)
					})
			})
		})

		viewHandles.push(
			view.on("click", (event) => {
				bgExpand.collapse()

				view.whenLayerView(objects).then((objectsView) => {
					watchUtils
						.whenNotOnce(objectsView, "updating")
						.then(() => {
							return objectsView.queryFeatures({
								geometry: event.mapPoint,
								where: objectsView.filter.where,
								distance: view.resolution <= 7 ? view.resolution * 15 : 100,
								spatialRelationship: "intersects",
								outFields: ["GlobalID", "OBJECTID"],
							})
						})
						.then((response) => {
							if (response.features.length > 0) {
								props.setMapQuery(response.features)
								navigate(
									`/vilniausdnr/${
										i18n.language
									}/plaques/object/${response.features[0].attributes.GlobalID.replace(/[{}]/g, "")}`
								)
							}
						})
				})
			})
		)

		if (isMobile) {
			view.when(() => {
				let tooltipDivCurrent = tooltipDiv.current

				tooltipDivCurrent = document.createElement("div")
				tooltipDivCurrent.className = "tooltip"
				mapDiv.current.appendChild(tooltipDivCurrent)

				let layerViewsCollection = []
				let highlights = []

				layerViewsCollection = view.layerViews

				view.on("pointer-move", (event) => {
					view.hitTest(event, { exclude: view.graphics }).then((response) => {
						highlights.forEach((highlight) => {
							highlight.remove()
						})
						highlights = []

						let results = response.results.filter((result) => {
							return result.graphic.layer.type === "feature"
						})

						if (results.length > 0) {
							let tempPav = results[0].graphic.attributes[langFields[i18n.language][3]]
							if (!tempPav) {
								tempPav = results[0].graphic.attributes[langFields["lt"][3]]
							}

							tooltipDivCurrent.innerHTML = tempPav
							tooltipDivCurrent.style.display = tempPav && tempPav.length > 0 ? "block" : "none"
							tooltipDivCurrent.style.left = event.x + "px"
							tooltipDivCurrent.style.top = event.y + "px"

							results.forEach((result) => {
								layerViewsCollection.forEach((layerView) => {
									if (layerView.layer === result.graphic.layer) {
										const highlight = layerView.highlight(result.graphic)
										highlights.push(highlight)
									}
								})
							})
						} else {
							tooltipDivCurrent.style.display = "none"
						}
					})
				})
			})
		}
	}, [i18n.language])

	useEffect(() => {
		bgExpand.content.source.basemaps.items[0].title = t("sys.basemapLight")
		bgExpand.content.source.basemaps.items[1].title = t("sys.basemapDark")
		bgExpand.content.source.basemaps.items[2].title = t("sys.basemapOrto")

		view.ui.components = []
		view.ui.components = ["zoom"]
		view.ui.add(bgExpand, "top-left")
		view.ui.add(locateWidget, "top-left")
		view.ui.move("zoom", "top-left")
	}, [i18n.language])

	useEffect(() => {
		return () => {
			viewHandles.forEach((handle) => {
				handle.remove()
			})
			viewHandles.length = 0

			view.container = null
		}
	}, [])

	return (
		<div id="view1Div" className="map" ref={mapDiv}>
			<div className="attribution">
				<div className="attribution_text">
					© 2023 UAB "ID Vilnius" © 2023 Vilniaus miesto savivaldybė
				</div>
				<div className="attribution_esri">Powered by Esri</div>
			</div>
		</div>
	)
}

export default ObjectMap
