import Typography from "@mui/material/Typography"
import Link from "@mui/material/Link"
import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"

import Y700 from "../../utils/icons/aboutIcons/700.svg"
import LMAVB from "../../utils/icons/aboutIcons/LMAVB.png"
import MELC_DNR from "../../utils/icons/aboutIcons/MELC_DNR.png"
import Vilnius_vp from "../../utils/icons/aboutIcons/vilnius_vp.svg"
import box from "../../utils/icons/aboutIcons/box.svg"
import map from "../../utils/icons/aboutIcons/map.svg"
import pin from "../../utils/icons/aboutIcons/pin.svg"

export const textDict = {
	lt: {
		about: (
			<Box sx={{ mr: 4 }}>
				<Box sx={{ mx: "2%" }}>
					<Grid
						sx={{ display: "flex", alignItems: "stretch" }}
						container
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
					>
						<Box
							sx={{ backgroundColor: "#D72E31", width: "40%", minHeight: "60vh", mt: "6%", p: 4 }}
							className="aboutBox"
						>
							<Typography
								sx={{ color: "white", fontSize: "24px", fontWeight: 600, mt: 2, textAlign: "center" }}
							>
								Vilniaus DNR projektas
							</Typography>
							<Typography
								sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 2, textAlign: "center" }}
							>
								„Vilniaus DNR“ portalas – tai virtualūs interneto vartai į Vilniaus istoriją. Čia rasite
								istorinius miesto žemėlapius, kuriuose užkoduota Vilniaus praeitis bei įrankius, kurie padės
								šį painų kodą įveikti. Skirtingose portalo skiltyse pateikta informacija padės geriau pažinti
								Vilnių – rasite istorinių topografijų rekonstrukcijas, istorines nuotraukas, dabartines ir
								istorines gatves, mieste įamžintas asmenybes, jų įamžinimo ženklus bei svarbiausius istorinius
								įvykius. Visa ši informacija ryšiais susieta tarpusavyje, taip sudarant daugialypį, bet
								vientisą ir unikalų Vilniaus kodą. „Vilniaus DNR“ kodą.
							</Typography>
							<Typography
								sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1, textAlign: "center" }}
							>
								Simboliška, kad „Vilniaus DNR“ portalas visuomenei atveriamas Vilniui švenčiant 700-ajį
								gimtadienį. Tai dovana Vilniaus miestui, vilniečiams, miesto svečiams bei visiems
								smalsuoliams, kurie domisi Vilniumi bei marga jo istorija.
							</Typography>

							<Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
								<Box
									sx={{
										width: "40%",
										mt: 3,
									}}
									component="img"
									src={Y700}
								/>
							</Grid>
						</Box>

						<Box
							sx={{ backgroundColor: "#EBEBEB", width: "40%", minHeight: "60vh", mt: "6%", p: 4 }}
							className="aboutBox"
						>
							<Typography
								sx={{ color: "black", fontSize: "24px", fontWeight: 600, mt: 2, textAlign: "center" }}
							>
								Kūrėjai
							</Typography>
							<Typography
								sx={{ color: "black", fontSize: "14px", fontWeight: 400, mt: 1, textAlign: "center" }}
							>
								Projektą kuria Vilniaus miesto savivaldybė ir UAB „ID Vilnius“.
							</Typography>
							<Grid container direction="row" justifyContent="center" alignItems="flex-end">
								<Box
									sx={{
										width: "50%",
										mt: 3,
										cursor: "pointer",
									}}
									component="img"
									src={Vilnius_vp}
									onClick={() => {
										window.open("https://www.idvilnius.lt/", "_blank")
									}}
								/>
							</Grid>
							<Typography
								sx={{ color: "black", fontSize: "24px", fontWeight: 600, mt: 4, textAlign: "center" }}
							>
								Partneriai
							</Typography>
							<Typography
								sx={{ color: "black", fontSize: "14px", fontWeight: 400, mt: 1, textAlign: "center" }}
							>
								Kuriant projektą savo medžiaga prisidėjo Mokslo ir enciklopedijų leidybos centras bei Lietuvos
								mokslų akademijos Vrublevskių biblioteka.
							</Typography>
							<Grid container direction="row" justifyContent="center" alignItems="flex-end">
								<Box
									sx={{
										width: "50%",
										mt: 3,
										cursor: "pointer",
									}}
									component="img"
									src={LMAVB}
									onClick={() => {
										window.open("http://www.mab.lt/", "_blank")
									}}
								/>
								<Box
									sx={{
										width: "50%",
										mt: 3,
										cursor: "pointer",
									}}
									component="img"
									src={MELC_DNR}
									onClick={() => {
										window.open("https://www.melc.lt/lt/", "_blank")
									}}
								/>
							</Grid>
						</Box>
					</Grid>
				</Box>
			</Box>
		),

		maps_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Ar kada ieškojote senų Vilniaus miesto žemėlapių? Daugiau neieškokite, nes „Vilniaus DNR“ portalo
					skiltyje „Istoriniai planai ir žemėlapiai“ rasite iš įvairių šaltinių integruotus istorinius
					Vilniaus miesto planus ir žemėlapius. Visi jie yra įskaitmeninti ir orientuoti šių laikų koordinačių
					sistemoje – istorinius žemėlapius galima lyginti su šių laikų. Šiuo metu esami žemėlapiai
					suskirstyti į keturias grupes:
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ul>
						<li>
							Topografiniai planai – tai detaliausi turimi stambaus mastelio (1:5000 ir stambesnio) istoriniai
							žemėlapiai, kuriuose sutartiniais ženklais tiksliai vaizduojami gamtiniai ir žmogaus sukurti
							žemės paviršiaus objektai. Dėl didelio detalumo šie žemėlapiai paprastai sudaryti iš skirtingų
							lapų.
						</li>
						<li>
							Istorinių topografijų rekonstrukcijos – tai atkurti ir patikslinti topografiniai planai. Apie
							rekonstrukcijas daugiau informacijos pateikiama <b>“Miestas atskirais istoriniais periodais”</b>{" "}
							skiltyje.
						</li>
						<li>
							Ortofotografiniai žemėlapiai – tai žemėlapiai, kuriuos sudaro iš orlaivių padarytų žemės
							paviršiaus (aerofotografinių) nuotraukų.
						</li>
						<li>
							LMA Vrublevskių bibliotekos žemėlapiai – tai 2015-2016 m. Lietuvos mokslų akademijos Vrublevskių
							bibliotekos projekto „Užmirštasis Vilnius“ metu skaitmenizuoti 49 žemėlapiai. Šie žemėlapiai
							sudaryti XIX–XX a., jų mastelis varijuoja tarp 1:8 000 ir 1:40 000, žemėlapių pavadinimai
							pateikiami originalia forma.
						</li>
					</ul>
				</Typography>
			</Box>
		),
		maps_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Istorinis planas arba žemėlapis atveriamas spaudžiant mygtuką ekrano apačioje, pasirenkant
							norimą grupę ir žemėlapį.
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2, width: "60%", height: "100%", resizeMode: "contain" }}
								component="img"
								src="https://i.ibb.co/hFtkgz3/25-01-2023-19-43-51-REC-Adobe-Express.gif"
								className="helpGif"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							Istorinio plano ar žemėlapio permatomumas keičiamas slankiojant valdiklį ekrano viršuje, kairėje
							pusėje.
						</li>
						<li style={{ paddingLeft: 4 }}>
							Istorinių planų ar žemėlapių peržiūros režimas keičiamas spaudžiant mygtuką ekrano viršuje,
							kairėje pusėje. Galimi trys režimai:
						</li>
						<ul style={{ listStyleType: "disc" }}>
							<li>Peržiūros režimas: vaizduojamas vienas pasirinktas žemėlapis.</li>
							<li>
								Slenkančios juostos režimas: ekranas padalinamas į dvi dalis, vaizduojami du skirtingi
								pasirinkti žemėlapiai. Jie lyginami slankiojant juostą.
							</li>
							<li>
								Dviejų langų režimas: ekranas padalinamas į dvi dalis, vaizduojami du skirtingi pasirinkti
								žemėlapiai, suvienodinamas jų priartinimas ir aprėptis. Žemėlapiai lyginami valdant vieną iš
								jų.
							</li>
						</ul>
					</ol>
					<Grid container direction="row" justifyContent="center" alignItems="center">
						<Box
							sx={{ my: 2, mr: 2 }}
							component="img"
							src="https://i.ibb.co/7rpDBBz/25-01-2023-19-53-24-REC-Adobe-Express.gif"
						/>
					</Grid>
				</Typography>
			</Box>
		),

		streets_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Turbūt daugelis vilniečių yra atkreipę dėmesį, kad Žvėryne yra daug gatvių, kurios pavadintos žvėrių
					pavadinimais. Bet ar žinote, kad tokių vienodos kilmės pavadinimų gatvių santalkų Vilniaus mieste
					yra ir daugiau? Pavyzdžiui, Balsiuose yra gatvių santalka pavadinta baltų dievybių, o Pavilnyje –
					tautų vardais. Kviečiame susipažinti su Vilniaus gatvėmis ir jų kilme.
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Portalo miesto gatvių skiltį sudaro dvi dalys – dabartis ir praeitis.
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					<b>Dabarties skiltyje</b> pateikiama informacija apie visas miesto gatves. Jos suklasifikuotos pagal
					pavadinimų reikšmes, pagal kurias galite filtruoti gatves. Pavyzdžiui, galite sužinoti, kiek
					Vilniaus mieste gatvių pavadintų rašytojų vardais. Taip pat pateikiama informacija apie kiekvienos
					gatvės ar jos dalies pavadinimus, kurie randami istoriniuose žemėlapiuose (originalia forma).
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					<b>Praeities skiltyje</b> pateikiama informacija apie centrinės Vilniaus miesto dalies gatvių
					istoriją - 5 istorinių periodų pavadinimus ir ašines linijas, kurias galima lyginti tarpusavyje.
					Taip pat pateikiamos nuorodos į originalius žemėlapius, kuriuos naudojant istoriniai gatvių
					pavadinimai ir ašinės linijos buvo įskaitmenintos.
				</Typography>
			</Box>
		),
		streets_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Gatvės informacija pateikiama paspaudus ant jos linijos žemėlapyje arba pasirenkant gatvę iš
							sąrašo paieškos skiltyje. Atsivėrusiame informacijos lange, susijusi istorinė medžiaga
							pasiekiama spaudžiant interaktyvų tekstą.
						</li>
						<li style={{ paddingLeft: 4 }}>
							Gatvių paieškos ir filtravimo funkcijos pateikiamos paieškos skiltyje, kairėje ekrano pusėje.
						</li>
						<ul style={{ listStyleType: "disc" }}>
							<li>Paieška atliekama įrašant gatvės pavadinimą į paieškos eilutę.</li>
							<li>
								Gatvių sąrašas filtruojamas pasirenkant objekto klasę ir poklasį. Norint pašalinti filtrus,
								spausti mygtuką išvalyti filtrus.
							</li>
						</ul>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2 }}
								component="img"
								src="https://i.ibb.co/hX0Cr4y/25-01-2023-20-01-22-REC-Adobe-Express.gif"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							Istorinių gatvių tinklo informacija skirtingais periodais pasiekiama spaudžiant mygtuką
							praeitis, ekrano viršuje.
						</li>
						<li style={{ paddingLeft: 4 }}>
							Gatvių tinklo metai pasirenkami spaudžiant mygtukus ekrano apačioje.
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2, width: "60%", height: "100%", resizeMode: "contain" }}
								component="img"
								src="https://i.ibb.co/tYYZQ1X/25-01-2023-21-06-44-REC-Adobe-Express.gif"
								className="helpGif"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							Gatvės informacija pateikiama paspaudus ant jos linijos žemėlapyje. Atsivėrusiame informacijos
							lange, susijusi istorinė medžiaga pasiekiama spaudžiant interaktyvų tekstą.
						</li>
					</ol>
				</Typography>
			</Box>
		),

		persons_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Skiltyje pateikiama informacija apie asmenybes, kurioms Vilniaus mieste įrengtos atminimo lentos,
					skulptūros, paminklai ar jų vardais pavadintos gatvės. Informacija apima:
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Išskirtus pagrindinius asmenybės biografijos faktus. Faktai susiję su Vilniumi išskirti raudona
							spalva.
						</li>
						<li style={{ paddingLeft: 4 }}>Ryšio su Vilniumi aprašymą;</li>
						<li style={{ paddingLeft: 4 }}>Asmenybei Vilniuje įrengtų skulptūrų ir paminklų nuorodas;</li>
						<li style={{ paddingLeft: 4 }}>Asmenybei Vilniuje įrengtų atminimo lentų nuorodas;</li>
						<li style={{ paddingLeft: 4 }}>Asmenybės vardu pavadintų gatvių nuorodas;</li>
						<li style={{ paddingLeft: 4 }}>
							Asmenybės palaidojimo vietos nuorodą, jei asmenybė palaidota Vilniaus mieste ir palaidojimo
							vieta yra žinoma;
						</li>
						<li style={{ paddingLeft: 4 }}>
							Su asmenybe susijusius Vilniaus miestui svarbius istorinius įvykius;
						</li>
						<li style={{ paddingLeft: 4 }}>Su asmenybe susijusias kitas asmenybes.</li>
					</ol>
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					Daugumos asmenybių aprašymai parengti remiantis pagrindiniu moksliniu šaltiniu „Visuotinė lietuvių
					enciklopedija“, kurios straipsnius rengia Lietuvos mokslininkai, įvairių sričių specialistai bei
					moksliniai redaktoriai. „Visuotinė lietuvių enciklopedija“ pasiekiama interneto adresu{" "}
					<Link href="https://www.vle.lt" target="_blank">
						www.vle.lt
					</Link>
					, ją administruoja Mokslo ir enciklopedijų leidybos centras.
				</Typography>
			</Box>
		),
		persons_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Mieste įamžintos asmenybės informacija pateikiama pasirinkus asmenybę iš sąrašo kairėje ekrano
							pusėje, paieškos skiltyje.
							<ul style={{ listStyleType: "disc" }}>
								<li>Paieška atliekama įrašant asmenybės vardą/pavardę į paieškos eilutę.</li>
								<li>
									Asmenybių sąrašas filtruojamas pasirenkant asmens profesiją ir veiklą. Norint pašalinti
									filtrus, spausti mygtuką išvalyti filtrus.
								</li>
							</ul>
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2 }}
								component="img"
								src="https://i.ibb.co/qpTyx9g/25-01-2023-20-08-56-REC-Adobe-Express.gif"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							Asmenybės aprašymo skiltyje susijusi istorinė medžiaga pasiekiama spaudžiant interaktyvų tekstą.
						</li>
					</ol>
				</Typography>
			</Box>
		),

		foto_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Skiltyje pateikiamos senosios Vilniaus miesto fotografijos ir atvirutės – jų darymo vieta pažymėta
					žemėlapyje, fotografijų galima ieškoti pagal jų darymo datą, autorių ar žemėlapio aprėptį. Šiuo metu
					visos skiltyje pateikiamos 183 nuotraukos integruotos iš 2015–2016 m. LMA Vrublevskių bibliotekos
					vykdyto projekto „Užmirštasis Vilnius“. Didžioji integruotų fotografijų dalis padaryta dviejų garsių
					Vilniaus fotografų – Jano Bulhako ir Stanislovo Filiberto Flerio.
				</Typography>
			</Box>
		),
		foto_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Fotografija ir jos informacija pateikiama paspaudus ant simbolio žemėlapyje arba pasirenkant
							fotografiją iš sąrašo kairėje ekrano pusėje, paieškos skiltyje. Atsivėrusiame informacijos
							lange, susijusi istorinė medžiaga pasiekiama spaudžiant interaktyvų tekstą.
						</li>
						<li style={{ paddingLeft: 4 }}>
							Fotografijų ir atviručių paieškos bei filtravimo funkcijos pateikiamos paieškos skiltyje,
							kairėje ekrano pusėje:
						</li>
						<ul style={{ listStyleType: "disc" }}>
							<li>Paieška atliekama įrašant fotografijos pavadinimą į paieškos eilutę.</li>
							<li>
								Fotografijų sąrašas filtruojamas pasirenkant fotografijos autorių arba įamžinimo metus. Metai
								nustatomi slenkant metų juostą, pasirenkama laikotarpio pradžios ir pabaigos data . Norint
								pašalinti filtrus, spausti mygtuką išvalyti filtrus.
							</li>
							<li>
								Fotografijų sąrašas filtruojamas pagal žemėlapio aprėptyje matomus objektus paspaudus mygtuką
								“Sąraše rodyti tik žemėlapio aprėptyje matomus objektus”.
							</li>
						</ul>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2 }}
								component="img"
								src="https://i.ibb.co/1MJLF3t/25-01-2023-20-22-41-REC-Adobe-Express.gif"
							/>
						</Grid>
					</ol>
				</Typography>
			</Box>
		),

		plaques_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Skiltyje pateikiama informacija apie visas (tiek Vilniaus miesto, tiek privačiomis iniciatyvomis
					įrengtas) mieste esančias atminimo lentas, paminklus, skulptūras ir kitus meninius ar mažosios
					architektūros objektus, kuriuos projekto autoriams pavyko identifikuoti iš įvairių viešų šaltinių.
					Informacija pateikiama ir apie paskutiniu metu pašalintus objektus. Pačius objektus galima filtruoti
					pagal įrengimo laikotarpį (kai jis žinomas), pagal objekto tipą, ar atminties tipą (kam atminti
					objektas buvo įrengtas).
				</Typography>
			</Box>
		),
		plaques_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Skulptūros ar atminimo lentos informacija pateikiama paspaudus simboliį žemėlapyje arba
							pasirenkant objektą iš sąrašo kairėje ekrano pusėje, paieškos skiltyje. Atsivėrusiame
							informacijos lange, susijusi istorinė medžiaga pasiekiama spaudžiant interaktyvų tekstą.
						</li>
						<li style={{ paddingLeft: 4 }}>
							Skulptūrų ir atminimo lentų paieškos ir filtravimo funkcijos pateikiamos paieškos skiltyje,
							kairėje ekrano pusėje:
						</li>
						<ul style={{ listStyleType: "disc" }}>
							<li>Paieška atliekama įrašant skulptūros ar atminimo lentos pavadinimą į paieškos eilutę.</li>
							<li>
								Skulptūrų ir atminimo lentų sąrašas filtruojamas pasirenkant objekto tipą, atminimo tipą arba
								įrengimo laikotarpį. Norint pašalinti filtrus, reikia spausti mygtuką išvalyti filtrus.
							</li>
							<li>
								Skulptūrų ir atminimo lentų sąrašas filtruojamas pagal žemėlapio aprėptyje matomus objektus
								paspaudus mygtuką “Sąraše rodyti tik žemėlapio aprėptyje matomus objektus”.
							</li>
						</ul>
						<li>
							Skulptūrų ir atminimo lentų vaizdavimo sutartiniai ženklai keičiami vaizdavimo skiltyje, kairėje
							ekrano pusėje. Pasirenkamas vaizdavimas pagal objekto arba atminimo tipą.
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2 }}
								component="img"
								src="https://i.ibb.co/8cskrkx/25-01-2023-20-34-42-REC-Adobe-Express.gif"
							/>
						</Grid>
					</ol>
				</Typography>
			</Box>
		),

		periods_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Ar kada norėjote panagrinėti, kaip skirtingais laikotarpiais kito Vilnius? Jei taip, turbūt bandėte
					tą daryti nagrinėdami senus istorinius žemėlapius, tačiau juos vartant pastebėti mieste vykusius
					pokyčius dažnai nėra taip paprasta. Skirtingi istoriniai žemėlapiai sudaryti skirtingais masteliais,
					juose vaizduojami objektai būna skirtingai apibendrinti(tie patys objektai pateikiami skirtingu
					detalumu). Negana to, kuo žemėlapis senesnis, tuo jo tikslumas būna prastesnis. Kitaip tariant, net
					ir perklojus vieną žemėlapį su kitu neretai sudėtinga nustatyti, ar dominantis ir abiejuose
					žemėlapiuose vaizduojamas tas pats objektas yra toks pats, ar jau pakitęs. Praktiškai visuomet seni
					žemėlapiai net ir vizualizuojami skirtingu dizainu!
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Tai koks galėtų būti sprendimas, kuris įgalintų net ir kartografijoje neįgudusius žmones paprastai
					nagrinėti pokyčius, kurie vyko Vilniuje? Tai atlikti leidžia istorinių topografinių žemėlapių (arba
					tiesiog - topografijų) rekonstrukcijos, kurios ir yra pateikiamos “Miestas atskirais istoriniais
					periodais” skiltyje.
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Kaip tai atlikta? Išskirtume kelis etapus:
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					UAB "ID Vilnius" ekspertai istorinius žemėlapius atrinko vadovaudamiesi keliais svarbiais
					kriterijais: detalumas ir skirting Vilniaus istorinių laikotarpių reprezentacija:
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							1808 m. K. Grunerto planas realiai rodo iki carinio laikotarpio Vilnių;
						</li>
						<li style={{ paddingLeft: 4 }}>1845 m. planas – carinio laikotarpio vidurio;</li>
						<li style={{ paddingLeft: 4 }}>1911 m. – carinio laikotarpio pabaigos;</li>
						<li style={{ paddingLeft: 4 }}>1938 m. – tarpukario pabaigos;</li>
						<li style={{ paddingLeft: 4 }}>1977 m. – vėlyvojo sovietmečio miestą.</li>
					</ol>
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Visi išvardinti topografiniai žemėlapiai buvo įskaitmeninti – nuskenuoti, orientuoti šių laikų
					koordinačių sistemose, apkirpti ( tokie detalūs žemėlapiai būna sudaryti iš daug lapų, jųparaštės
					dengia viena kitą ir uždengia svarbius objektus), išvalyti, sutvarkytos spalvos ir ryškumas.
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Paskutiniame žemėlapių tvarkymo etape topografijos buvo rekonstruotos. Kiekvieną sename
					topografiniame žemėlapyje vaizduojamą objektą kartografijos ekspertas lygino su tiksliais
					skaitmeniniais šių laikų duomenimis ir vertino – istoriniame žemėlapyje vaizduojamas toks pats
					objektas kaip yra dabar, ar pakitęs. Jei toks pats kaip dabar – į rekonstrukciją objekto (pavyzdžiui
					statinio) kontūras buvo perkeliamas iš šių laikų skaitmeninių duomenų. Jei įvertinta, kad
					istoriniame žemėlapyje vaizduojamas kitoks objektas – jo kontūras iš žemėlapio tiksliai atkartotas,
					įtraukiant jį rekonstrukciją. Taip buvo rekonstruoti visi pasirinkti žemėlapiai, atkuriant juos nuo
					naujausių iki seniausių, vis vertinant atskirus geografinius objektus ir juos perkeliant iš prieš
					tai buvusio laikotarpio arba įbraižant naujai iš istorinio žemėlapio.
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					<b>Duomenų tikslumas ir kokybė.</b> Šios rekonstrukcijos – pagalba naudotojui lengviau lyginti
					skirtinguose istoriniuose žemėlapiuose pateikiamus duomenis, tačiau juose gali pasitaikyti
					netikslumų dėl anksčiau aprašyto rekonstrukcijos proceso. Į pateikiamus duomenis siūlome žiūrėti
					kritiškai, nes netikslumų pasitaiko net ir originaliuose naudotuose istoriniuose topografiniuose
					žemėlapiuose – jei autorius pavaizdavo žemėlapyje objektą, tai dar nereiškia, kad jis tikrai buvo
					toks (tai ypač aktualu XIX a. žemėlapiams).
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Kol kas rekonstruota tik centrinė, atrinktiems žemėlapiams bendra, Vilniaus miesto dalis.
				</Typography>
			</Box>
		),
		periods_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Istorinis periodas pasirenkamas spaudžiant mygtukus laiko juostoje, ekrano apačioje
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2, width: "60%", height: "100%", resizeMode: "contain" }}
								component="img"
								src="https://i.ibb.co/pWh75Hk/25-01-2023-20-41-12-REC-Adobe-Express.gif"
								className="helpGif"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							Žemėlapio informacija pateikiama viršuje, dešinėje ekrano pusėje. Susijusi istorinė medžiaga
							pasiekiama spaudžiant interaktyvų tekstą.
						</li>
						<li style={{ paddingLeft: 4 }}>
							Žemėlapių peržiūros režimas keičiamas spaudžiant mygtuką ekrano viršuje, kairėje pusėje. Galimi
							trys režimai:
						</li>
						<ul style={{ listStyleType: "disc" }}>
							<li>Laiko juostos režimas: vaizduojamas vienas, laiko juostoje pasirinktas žemėlapis.</li>
							<li>
								Slenkančios juostos režimas: ekranas sudalinamas į dvi dalis, vaizduojami du skirtingi
								pasirinkti žemėlapiai. Jie lyginami slankiojant juostą.
							</li>
							<li>
								Dviejų langų režimas: ekranas sudalinamas į dvi dalis, vaizduojami du skirtingi pasirinkti
								žemėlapiai, suvienodinamas jų priartinimas ir aprėptis. Žemėlapiai lyginami valdant vieną iš
								jų.
							</li>
						</ul>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2 }}
								component="img"
								src="https://i.ibb.co/rftYtYd/25-01-2023-20-46-55-REC-Adobe-Express.gif"
							/>
						</Grid>
					</ol>
				</Typography>
			</Box>
		),

		events_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Skiltyje pateikiama informacija apie svarbiausius Vilniaus miestui istorinius įvykius, kuriuos į
					Vilniaus atminties kultūros programoje sukurtą „Vilniaus miesto kalendorių“ įtraukė istorikai.
					Įvykiai sugrupuoti pagal Vilniaus istorijos periodus, susieti su susijusiomis asmenybėmis,
					nuotraukomis, atminimo ženklais ir žemėlapiais.
				</Typography>
			</Box>
		),
		events_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Istorinių įvykių juosta valdoma slenkant naršyklės langą aukštyn arba žemyn.
						</li>
						<li style={{ paddingLeft: 4 }}>
							Istorinio įvykio papildoma informacija matoma spaudžiant tekstą Plačiau..., žemiau įvykio
							nuotraukos bei pavadinimo. Susijusi istorinė medžiaga pasiekiama spaudžiant interaktyvų tekstą.
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2, width: "30%", height: "100%", resizeMode: "contain" }}
								component="img"
								src="https://i.ibb.co/jZfR5Kv/25-01-2023-20-59-25-REC-Adobe-Express.gif"
								className="helpGifTall"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							Istoriniai įvykiai gali būti filtruojami ir pasirenkami pagal istorinius laikotarpius,
							spaudžiant istorinių įvykių juostą kairėje ekrano pusėje.
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2, width: "30%", height: "100%", resizeMode: "contain" }}
								component="img"
								src="https://i.ibb.co/zH7nTpc/25-01-2023-20-54-44-REC-Adobe-Express.gif"
								className="helpGifTall"
							/>
						</Grid>
					</ol>
				</Typography>
			</Box>
		),

		contacts: (
			<Box sx={{ mt: 8, position: "relative" }}>
				<Grid container direction="column" justifyContent="center" alignItems="center">
					<Box
						sx={{
							width: 280,
						}}
						component="img"
						src={box}
					/>
					<Typography sx={{ maxWidth: 280, px: 2, position: "relative", mt: -13, textAlign: "center" }}>
						Turite klausimų ar pasiūlymų? Susisiekime!
					</Typography>
					<Box
						sx={{
							width: 300,
							mt: -2,
						}}
						component="img"
						src={map}
					/>
					<Box
						sx={{
							width: 30,
							mt: -24,
						}}
						component="img"
						src={pin}
					/>
					<Typography sx={{ color: "white", fontSize: "16px", fontWeight: 400, mt: 20 }}>
						Elektroninis paštas:
					</Typography>
					<Typography sx={{ color: "white", fontSize: "18px", fontWeight: 500, mt: 1 }}>
						vilniusdnr@vplanas.lt
					</Typography>
				</Grid>
			</Box>
		),
	},
	en: {
		///////////////////
		about: (
			<Box sx={{ mr: 4 }}>
				<Box sx={{ mx: "2%" }}>
					<Grid
						sx={{ display: "flex", alignItems: "stretch" }}
						container
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
					>
						<Box
							sx={{ backgroundColor: "#D72E31", width: "40%", minHeight: "60vh", mt: "6%", p: 4 }}
							className="aboutBox"
						>
							<Typography
								sx={{ color: "white", fontSize: "24px", fontWeight: 600, mt: 2, textAlign: "center" }}
							>
								Vilnius DNA Project
							</Typography>
							<Typography
								sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 2, textAlign: "center" }}
							>
								"Vilnius DNA" website - a virtual internet gateway to the history of Vilnius. Here you will
								find historical maps of the city that encode the past of Vilnius, and tools to help you crack
								this confusing code. The information in the different sections of the website will help you to
								get to know Vilnius better - you will find reconstructions of historical topographies,
								historical photographs, current and historical streets, personalities commemorated in the city
								and their signs, as well as the most important historical events. All this information is
								interlinked to form a diverse but coherent and unique code of Vilnius. "Vilnius DNA" code.
							</Typography>
							<Typography
								sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1, textAlign: "center" }}
							>
								It is symbolic that "Vilnius DNA" website is opened to the public on the occasion of Vilnius'
								700th birthday. It is a gift to the City of Vilnius, to the citizens of Vilnius, to the guests
								of the city and to all those who are curious about Vilnius and its colourful history.
							</Typography>

							<Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
								<Box
									sx={{
										width: "40%",
										mt: 3,
									}}
									component="img"
									src={Y700}
								/>
							</Grid>
						</Box>

						<Box
							sx={{ backgroundColor: "#EBEBEB", width: "40%", minHeight: "60vh", mt: "6%", p: 4 }}
							className="aboutBox"
						>
							<Typography
								sx={{ color: "black", fontSize: "24px", fontWeight: 600, mt: 2, textAlign: "center" }}
							>
								Developers
							</Typography>
							<Typography
								sx={{ color: "black", fontSize: "14px", fontWeight: 400, mt: 1, textAlign: "center" }}
							>
								The project is developed by Vilnius City Municipality and UAB "ID Vilnius".
							</Typography>
							<Grid container direction="row" justifyContent="center" alignItems="flex-end">
								<Box
									sx={{
										width: "50%",
										mt: 3,
										cursor: "pointer",
									}}
									component="img"
									src={Vilnius_vp}
									onClick={() => {
										window.open("https://www.idvilnius.lt/", "_blank")
									}}
								/>
							</Grid>
							<Typography
								sx={{ color: "black", fontSize: "24px", fontWeight: 600, mt: 4, textAlign: "center" }}
							>
								Partners
							</Typography>
							<Typography
								sx={{ color: "black", fontSize: "14px", fontWeight: 400, mt: 1, textAlign: "center" }}
							>
								The Science and Encyclopaedia Publishing Centre and the Wroblewski Library of the Lithuanian
								Academy of Sciences contributed to the project.
							</Typography>
							<Grid container direction="row" justifyContent="center" alignItems="flex-end">
								<Box
									sx={{
										width: "50%",
										mt: 3,
										cursor: "pointer",
									}}
									component="img"
									src={LMAVB}
									onClick={() => {
										window.open("http://www.mab.lt/", "_blank")
									}}
								/>
								<Box
									sx={{
										width: "50%",
										mt: 3,
										cursor: "pointer",
									}}
									component="img"
									src={MELC_DNR}
									onClick={() => {
										window.open("https://www.melc.lt/lt/", "_blank")
									}}
								/>
							</Grid>
						</Box>
					</Grid>
				</Box>
			</Box>
		),
		maps_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Have you ever looked for old maps of Vilnius? Look no further, because in the "Historical Plans and
					Maps" section of "Vilnius DNA" website you will find historical plans and maps of Vilnius integrated
					from various sources. All of them are digitised and oriented in the coordinate system of the present
					day - historical maps can be compared with modern ones. The existing maps are currently divided into
					four groups:
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ul>
						<li>
							Topographic maps - these are the most detailed large-scale (1:5000 and larger) historical maps
							available, which accurately depict natural and man-made objects on the earth's surface using
							standardised symbols. Due to their high level of detail, these maps are usually made up of
							different pages.
						</li>
						<li>
							Reconstructions of historical topographies are reconstructed and revised topographic plans. For
							more information on reconstructions, see the section{" "}
							<b>"The city in different historical periods"</b>.
						</li>
						<li>
							Orthophotographic maps - maps made of photographs of the earth's surface taken from aircrafts
							(aerial photographs).
						</li>
						<li>
							Maps of the Wroblewski Library of the Lithuanian Academy of Sciences - 49 maps digitised in
							2015-2016 within the framework of the project "Forgotten Vilnius" of the Wroblewski Library of
							the Lithuanian Academy of Sciences. The maps date from the XIX-XX centuries, their scale varies
							between 1:8,000 and 1:40,000, and the map titles are given in their original form.
						</li>
					</ul>
				</Typography>
			</Box>
		),
		maps_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							You can open the historical plan or map by pressing the button at the bottom of the screen,
							selecting the desired group and map.
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2, width: "60%", height: "100%", resizeMode: "contain" }}
								component="img"
								src="https://i.ibb.co/hFtkgz3/25-01-2023-19-43-51-REC-Adobe-Express.gif"
								className="helpGif"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							The transparency of the historical plan or map is changed by sliding the controller at the top
							left of the screen.
						</li>
						<li style={{ paddingLeft: 4 }}>
							The preview mode of historical plans or maps is changed by pressing the button at the top left
							of the screen. Three modes are available:
						</li>
						<ul style={{ listStyleType: "disc" }}>
							<li>Preview mode: one selected map is displayed.</li>
							<li>
								Scroll bar mode: the screen is split into two parts, showing two different selected maps. They
								are compared by sliding the bar.
							</li>
							<li>
								Dual window mode: the screen is divided into two parts, two different selected maps are
								displayed and their zoom and coverage are aligned. The maps are compared by controlling one of
								them.
							</li>
						</ul>
					</ol>
					<Grid container direction="row" justifyContent="center" alignItems="center">
						<Box
							sx={{ my: 2, mr: 2 }}
							component="img"
							src="https://i.ibb.co/7rpDBBz/25-01-2023-19-53-24-REC-Adobe-Express.gif"
						/>
					</Grid>
				</Typography>
			</Box>
		),

		streets_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Many people in Vilnius have probably noticed that there are many streets in Žvėrynas that are named
					after animals. But did you know that there are many more such street confluences in Vilnius with the
					same name? For example, in Balsiai there is a cluster of streets named after white deities, and in
					Pavilnys - after nations. We invite you to get to know the streets of Vilnius and their origins.
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					The website's city streets section is divided into two parts - present and past.
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					The <b>present section</b> contains information about all the streets of the city. They are
					classified according to the meanings of their names, which you can filter by. For example, you can
					find out how many streets in Vilnius are named after writers. It also provides information on the
					names of each street or part of a street that can be found on historical maps (in its original
					form).
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					The <b>past section</b> provides information about the history of the streets in the central part of
					Vilnius - the names of the 5 historical periods and the axial lines that can be compared with each
					other. It also provides links to the original maps that have been used to digitise the historical
					street names and axial lines.
				</Typography>
			</Box>
		),
		streets_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Street information is available by clicking on the street line on the map or by selecting a
							street from the list in the search box. In the information window that appears, related
							historical material is available by clicking on the interactive text.
						</li>
						<li style={{ paddingLeft: 4 }}>
							Street search and filtering functions are available in the search section on the left side of
							the screen.
						</li>
						<ul style={{ listStyleType: "disc" }}>
							<li>The search is carried out by typing the street name in the search box.</li>
							<li>
								The list of streets is filtered by selecting the object class and subclass. In order to remove
								the filters, click on the clear filters button.
							</li>
						</ul>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2 }}
								component="img"
								src="https://i.ibb.co/hX0Cr4y/25-01-2023-20-01-22-REC-Adobe-Express.gif"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							Information on the historical street network for different periods is available by pressing the
							past button at the top of the screen.
						</li>
						<li style={{ paddingLeft: 4 }}>
							The year of the street network is selected by pressing the buttons at the bottom of the screen.
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2, width: "60%", height: "100%", resizeMode: "contain" }}
								component="img"
								src="https://i.ibb.co/tYYZQ1X/25-01-2023-21-06-44-REC-Adobe-Express.gif"
								className="helpGif"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							Street information is displayed by clicking on the street line on the map. In the information
							window that appears, related historical material is accessed by clicking on the interactive
							text.
						</li>
					</ol>
				</Typography>
			</Box>
		),

		persons_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					This section provides information about the persons for whom plaques, statues, monuments have been
					built in Vilnius or streets named after them. The information includes:
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Highlights the main facts of the biography of the person. Facts related to Vilnius highlighted
							in red.
						</li>
						<li style={{ paddingLeft: 4 }}>A description of his/her connection to Vilnius;</li>
						<li style={{ paddingLeft: 4 }}>
							Indication of statues and monuments dedicated to the person in Vilnius;
						</li>
						<li style={{ paddingLeft: 4 }}>
							Links to commemorative plaques placed in Vilnius for the person;
						</li>
						<li style={{ paddingLeft: 4 }}>Links to streets named after the person;</li>
						<li style={{ paddingLeft: 4 }}>
							Indication of the burial place of the person, if the person is buried in Vilnius and the burial
							place is known;
						</li>
						<li style={{ paddingLeft: 4 }}>
							Historical events related to the person that are of importance to Vilnius City;
						</li>
						<li style={{ paddingLeft: 4 }}>Other persons related to the person.</li>
					</ol>
				</Typography>

				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					The descriptions of most of the persons are based on the main scientific source, the "Universal
					Lithuanian Encyclopedia", the articles of which are prepared by Lithuanian scientists, specialists
					in various fields and scientific editors. The "Universal Lithuanian Encyclopedia" is available
					online at{" "}
					<Link href="https://www.vle.lt" target="_blank">
						www.vle.lt
					</Link>
					and is administered by the Science and Encyclopaedia Publishing Centre.
				</Typography>
			</Box>
		),
		persons_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Information about a person commemorated in the city is available by selecting the person from
							the list on the left side of the screen, in the search box.
							<ul style={{ listStyleType: "disc" }}>
								<li>The search is carried out by typing the name of the person in the search box.</li>
								<li>
									The list of persons is filtered by selecting the person's profession and activity. In order
									to remove the filters, click on the clear filters button.
								</li>
							</ul>
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2 }}
								component="img"
								src="https://i.ibb.co/qpTyx9g/25-01-2023-20-08-56-REC-Adobe-Express.gif"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							In the profile section of the person, related historical material is accessed by clicking on the
							interactive text.
						</li>
					</ol>
				</Typography>
			</Box>
		),

		foto_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					This section contains old photographs and postcards of Vilnius - the location of the photographs is
					marked on the map, and the photographs can be searched by date, author or map coverage. Currently,
					all 183 photographs in the section are integrated from the project "Forgotten Vilnius", carried out
					by the Wroblewski Library of the Lithuanian Academy of Sciences in 2015-2016. The majority of the
					integrated photographs were taken by two famous Vilnius photographers, Janas Bulhakas and
					Stanislovas Filibertas Fleris.
				</Typography>
			</Box>
		),
		foto_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							The photograph and its information can be accessed by clicking on the symbol on the map, or by
							selecting the photograph from the list on the left-hand side of the screen in the search box. In
							the information window that appears, related historical material can be accessed by clicking on
							the interactive text.
						</li>
						<li style={{ paddingLeft: 4 }}>
							The search and filter functions for photographs and postcards are available in the search box on
							the left-hand side of the screen:
						</li>
						<ul style={{ listStyleType: "disc" }}>
							<li>Search by entering the name of the photograph in the search box.</li>
							<li>
								The list of photographs can be filtered by selecting the photographer or the year the
								photograph was taken. The year is set by scrolling through the year bar, selecting the start
								and end date of the period. In order to remove the filters, click on the clear filters button.
							</li>
							<li>
								The list of photographs is filtered according to the objects visible on the map by pressing
								the button "Show only objects visible on the map in the list".
							</li>
						</ul>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2 }}
								component="img"
								src="https://i.ibb.co/1MJLF3t/25-01-2023-20-22-41-REC-Adobe-Express.gif"
							/>
						</Grid>
					</ol>
				</Typography>
			</Box>
		),

		plaques_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					The section provides information on all the memorial plaques, monuments, sculptures and other
					artistic or small architectural objects in the city (installed both by the City of Vilnius and
					through private initiatives), which the authors of the project have managed to identify from various
					public sources. Information is also provided on recently removed objects. The objects themselves can
					be filtered by the period of installation (when known), by the type of object, or by the type of
					memory (what the object was installed to commemorate).
				</Typography>
			</Box>
		),
		plaques_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							"Information about a sculpture or plaque can be found by clicking on the symbol on the map, or
							by selecting the object from the list on the left-hand side of the screen in the search box. In
							the information window that appears, related historical material can be accessed by clicking on
							the interactive text.
						</li>
						<li style={{ paddingLeft: 4 }}>
							The search and filter functions for statues and memorial plaques are available in the search box
							on the left-hand side of the screen:
						</li>
						<ul style={{ listStyleType: "disc" }}>
							<li>Search by typing the name of the sculpture or plaque into the search box.</li>
							<li>
								The list of sculptures and plaques is filtered by the type of object, the type of
								commemoration or the period of installation. In order to remove the filters, you need to click
								on the clear filters button.
							</li>
							<li>
								The list of statues and plaques is filtered by the objects visible in the map coverage by
								clicking on the button "Show only objects visible in the map in the list".
							</li>
						</ul>
						<li>
							The standard symbols for the display of statues and plaques is changed in the display column on
							the left-hand side of the screen. Select the type of object or memorial to be displayed.
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2 }}
								component="img"
								src="https://i.ibb.co/8cskrkx/25-01-2023-20-34-42-REC-Adobe-Express.gif"
							/>
						</Grid>
					</ol>
				</Typography>
			</Box>
		),

		periods_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Have you ever wanted to look at how Vilnius has evolved in different periods? If so, you've probably
					tried looking at old historical maps, but it's often not so easy to see the changes that have taken
					place in the city. Different historical maps are drawn at different scales and show different
					generalisations (the same objects are shown in different levels of detail). Moreover, the older the
					map, the poorer its accuracy. In other words, even when overlaying one map with another, it is often
					difficult to determine whether the object of interest is the same on both maps or whether it has
					changed. In practice, old maps are always visualized in a different design!
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					So what could be the solution that would enable even people who are not cartographically literate to
					easily analyse the changes that have taken place in Vilnius? The reconstructions of historical
					topographic maps (or simply topographies), which are presented in the "City in different historical
					periods" section, make this possible.
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					How was this done? We can distinguish several stages:
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					The experts of UAB "ID Vilnius" selected the historical maps based on several important
					criteria: detail and representation of different historical periods of Vilnius:
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							The plan of 1808 by K. Grunertas realistically shows Vilnius before the Tsarist period;
						</li>
						<li style={{ paddingLeft: 4 }}>The plan of 1845 represents the mid Tsarist period;</li>
						<li style={{ paddingLeft: 4 }}>1911 - the end of the Tsarist period;</li>
						<li style={{ paddingLeft: 4 }}>1938 - the end of the interwar period;</li>
						<li style={{ paddingLeft: 4 }}>1977 - the city of the late Soviet period.</li>
					</ol>
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					All the topographic maps listed above have been digitised - scanned, oriented in contemporary
					coordinate systems, cropped (such detailed maps tend to be multi-sheeted, with overlapping margins
					and overlapping landmarks), cleaned, and colour and brightness corrected.
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					In the last stage of the mapping process, the topographies were reconstructed. For each object
					depicted on the old topographic map, a cartographic expert compared it with accurate digital data
					from the present day and assessed whether the historical map depicted the same object as it is now
					or whether it had changed. If it is the same as now, the outline of the object (e.g. a building) was
					transferred into the reconstruction from the digital data of that time. If the historical map is
					estimated to show a different object, the outline of the object has been accurately reproduced from
					the map and included in the reconstruction. In this way, all the selected maps were reconstructed,
					from the most recent to the oldest, with individual geographical features still being assessed and
					either transferred from the previous period or redrawn from the historical map.
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					<b>Data accuracy and quality</b>. These reconstructions help the user to compare data from different
					historical maps, but may contain inaccuracies due to the reconstruction process described above. It
					is advisable to be critical of the data provided, as inaccuracies can occur even on the original
					historical topographic maps used - just because an author has depicted an object on a map, it does
					not mean that it was actually there (this is particularly true for the maps of the XIX century).
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					So far, only the central part of Vilnius, which is shared by the selected maps, has been
					reconstructed.
				</Typography>
			</Box>
		),
		periods_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							The historical period is selected by pressing the buttons in the timeline at the bottom of the
							screen
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2, width: "60%", height: "100%", resizeMode: "contain" }}
								component="img"
								src="https://i.ibb.co/pWh75Hk/25-01-2023-20-41-12-REC-Adobe-Express.gif"
								className="helpGif"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							Map information is displayed at the top right of the screen. Related historical material is
							accessed by clicking on the interactive text.
						</li>
						<li style={{ paddingLeft: 4 }}>
							The map view mode is changed by pressing the button at the top left of the screen. Three modes
							are available:
						</li>
						<ul style={{ listStyleType: "disc" }}>
							<li>Timeline mode: displays a single map selected in the timeline.</li>
							<li>
								Scrolling bar mode: the screen is split into two parts, showing two different selected maps.
								They are compared by sliding the bar.
							</li>
							<li>
								Dual window mode: the screen is split into two, two different selected maps are displayed, and
								their zoom and coverage are aligned. The maps are compared by controlling one of them.
							</li>
						</ul>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2 }}
								component="img"
								src="https://i.ibb.co/rftYtYd/25-01-2023-20-46-55-REC-Adobe-Express.gif"
							/>
						</Grid>
					</ol>
				</Typography>
			</Box>
		),

		events_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					This section provides information on the most important historical events in Vilnius that have been
					included by historians in the "Vilnius City Calendar" created by the Vilnius Memory Culture
					Programme. The events are grouped according to the periods of Vilnius' history, linked to the
					persons involved, photographs, commemorative signs and maps.
				</Typography>
			</Box>
		),
		events_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							The history bar is managed by scrolling up or down in the browser window.
						</li>
						<li style={{ paddingLeft: 4 }}>
							Additional information about a historical event can be seen by clicking on the text More...,
							below the event picture and name. Related historical material is available by clicking on the
							interactive text.
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2, width: "30%", height: "100%", resizeMode: "contain" }}
								component="img"
								src="https://i.ibb.co/jZfR5Kv/25-01-2023-20-59-25-REC-Adobe-Express.gif"
								className="helpGifTall"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							Historical events can be filtered and selected by historical periods by clicking on the
							historical events bar on the left of the screen.
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2, width: "30%", height: "100%", resizeMode: "contain" }}
								component="img"
								src="https://i.ibb.co/zH7nTpc/25-01-2023-20-54-44-REC-Adobe-Express.gif"
								className="helpGifTall"
							/>
						</Grid>
					</ol>
				</Typography>
			</Box>
		),

		contacts: (
			<Box sx={{ mt: 8, position: "relative" }}>
				<Grid container direction="column" justifyContent="center" alignItems="center">
					<Box
						sx={{
							width: 280,
						}}
						component="img"
						src={box}
					/>
					<Typography sx={{ maxWidth: 280, px: 2, position: "relative", mt: -13, textAlign: "center" }}>
						Any questions or suggestions? Contact us!
					</Typography>
					<Box
						sx={{
							width: 300,
							mt: -2,
						}}
						component="img"
						src={map}
					/>
					<Box
						sx={{
							width: 30,
							mt: -24,
						}}
						component="img"
						src={pin}
					/>
					<Typography sx={{ color: "white", fontSize: "16px", fontWeight: 400, mt: 20 }}>E-mail:</Typography>
					<Typography sx={{ color: "white", fontSize: "18px", fontWeight: 500, mt: 1 }}>
						vilniusdnr@vplanas.lt
					</Typography>
				</Grid>
			</Box>
		),
	}, /////////////////////
	ru: {
		about: (
			<Box sx={{ mr: 4 }}>
				<Box sx={{ mx: "2%" }}>
					<Grid
						sx={{ display: "flex", alignItems: "stretch" }}
						container
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
					>
						<Box
							sx={{ backgroundColor: "#D72E31", width: "40%", minHeight: "60vh", mt: "6%", p: 4 }}
							className="aboutBox"
						>
							<Typography
								sx={{ color: "white", fontSize: "24px", fontWeight: 600, mt: 2, textAlign: "center" }}
							>
								Проект ДНК Вильнюса
							</Typography>
							<Typography
								sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 2, textAlign: "center" }}
							>
								Проект ДНК Вильнюса Портал «ДНК Вильнюса» - это виртуальные интернет-ворота в историю
								Вильнюса. Здесь вы найдете исторические карты города, в которых закодировано прошлое Вильнюса,
								и инструменты, которые помогут вам разгадать этот запутанный код. Информация, представленная в
								различных разделах портала, поможет вам лучше узнать Вильнюс – вы найдете реконструкции
								исторических топографий, исторические фотографии, современные и исторические улицы,
								увековеченных в городе личностей, их памятные знаки и самые важные исторические события. Вся
								эта информация связана друг с другом, образуя многогранный, но целостный и уникальный код
								Вильнюса. Код «ДНК Вильнюса».
							</Typography>
							<Typography
								sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1, textAlign: "center" }}
							>
								Символично, что портал «ДНК Вильнюса» открывается для общественности в связи с празднованием
								700-летия Вильнюса. Это подарок городу Вильнюсу, жителям Вильнюса, гостям города и всем
								любознательным, интересующимся Вильнюсом и его красочной историей.
							</Typography>

							<Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
								<Box
									sx={{
										width: "40%",
										mt: 3,
									}}
									component="img"
									src={Y700}
								/>
							</Grid>
						</Box>

						<Box
							sx={{ backgroundColor: "#EBEBEB", width: "40%", minHeight: "60vh", mt: "6%", p: 4 }}
							className="aboutBox"
						>
							<Typography
								sx={{ color: "black", fontSize: "24px", fontWeight: 600, mt: 2, textAlign: "center" }}
							>
								Создатели
							</Typography>
							<Typography
								sx={{ color: "black", fontSize: "14px", fontWeight: 400, mt: 1, textAlign: "center" }}
							>
								Проект разрабатывается Вильнюсским городским самоуправлением и ЗАО «Вильняус планас».
							</Typography>
							<Grid container direction="row" justifyContent="center" alignItems="flex-end">
								<Box
									sx={{
										width: "50%",
										mt: 3,
										cursor: "pointer",
									}}
									component="img"
									src={Vilnius_vp}
									onClick={() => {
										window.open("https://www.idvilnius.lt/", "_blank")
									}}
								/>
							</Grid>
							<Typography
								sx={{ color: "black", fontSize: "24px", fontWeight: 600, mt: 4, textAlign: "center" }}
							>
								Партнеры
							</Typography>
							<Typography
								sx={{ color: "black", fontSize: "14px", fontWeight: 400, mt: 1, textAlign: "center" }}
							>
								Свой материал для создания проекта предоставили Научно-энциклопедический издательский центр и
								Библиотека Академии наук Литвы имени Врублевских.
							</Typography>
							<Grid container direction="row" justifyContent="center" alignItems="flex-end">
								<Box
									sx={{
										width: "50%",
										mt: 3,
										cursor: "pointer",
									}}
									component="img"
									src={LMAVB}
									onClick={() => {
										window.open("http://www.mab.lt/", "_blank")
									}}
								/>
								<Box
									sx={{
										width: "50%",
										mt: 3,
										cursor: "pointer",
									}}
									component="img"
									src={MELC_DNR}
									onClick={() => {
										window.open("https://www.melc.lt/lt/", "_blank")
									}}
								/>
							</Grid>
						</Box>
					</Grid>
				</Box>
			</Box>
		),

		maps_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Вы когда-нибудь искали старые карты города Вильнюса? Не ищите дальше, потому что в разделе
					«Исторические планы и карты» портала «ДНК Вильнюса» вы найдете объединенные исторические планы и
					карты города Вильнюса из различных источников. Все они оцифрованы и ориентированы в современной
					системе координат — исторические карты можно сравнивать с современными. В настоящее время
					существующие карты разделены на четыре группы:
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ul>
						<li>
							Топографические планы — наиболее подробные из имеющихся исторических карт крупного масштаба
							(1:5000 и крупнее), на которых условными знаками точно изображены естественные и рукотворные
							особенности земной поверхности. Из-за высокого уровня детализации эти карты обычно состоят из
							разных листов.
						</li>
						<li>
							Реконструкции исторических топографий представляют собой восстановленные и пересмотренные
							топографические планы. Подробнее о реконструкциях можно узнать в разделе «Город в отдельные
							исторические периоды».
						</li>
						<li>
							Ортофотографические карты — карты, составленные по аэрофотоснимкам земной поверхности, сделанным
							с самолетов.
						</li>
						<li>
							Карты Библиотеки АНЛ имени Врублевских за 2015-2016 годы. В рамках проекта «Забытый Вильнюс»
							библиотеки Литовской академии наук имени Врублевских было оцифровано 49 карт. Эти карты
							выполнены в XIX и XX веках, их масштаб варьируется от 1:8 000 до 1:40 000, названия карт
							представлены в первоначальном виде.
						</li>
					</ul>
				</Typography>
			</Box>
		),
		maps_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Исторический план или карта открывается нажатием кнопки внизу экрана, выбрав нужную группу и
							карту.
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2, width: "60%", height: "100%", resizeMode: "contain" }}
								component="img"
								src="https://i.ibb.co/hFtkgz3/25-01-2023-19-43-51-REC-Adobe-Express.gif"
								className="helpGif"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							Прозрачность исторического плана или карты можно изменить, сдвинув регулятор в верхней левой
							части экрана.
						</li>
						<li style={{ paddingLeft: 4 }}>
							Режим просмотра исторических планов или карт изменяется нажатием кнопки в верхней левой части
							экрана. Доступны три режима:
						</li>
						<ul style={{ listStyleType: "disc" }}>
							<li>Режим просмотра: отображается одна выбранная карта.</li>
							<li>
								Режим полосы прокрутки: разделяет экран на две части, показывая две разные выбранные карты.
								Они сравниваются путем прокрутки полосы.
							</li>
							<li>
								Режим двойного окна: разделяет экран на две части, показывая две разные выбранные карты,
								объединяя их масштаб и покрытие. Карты сравниваются, управляя одной из них.
							</li>
						</ul>
					</ol>
					<Grid container direction="row" justifyContent="center" alignItems="center">
						<Box
							sx={{ my: 2, mr: 2 }}
							component="img"
							src="https://i.ibb.co/7rpDBBz/25-01-2023-19-53-24-REC-Adobe-Express.gif"
						/>
					</Grid>
				</Typography>
			</Box>
		),

		streets_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Многие жители Вильнюса, наверное, заметили, что в Жверинасе есть много улиц, названных в честь
					животных. Но знаете ли вы, что таких названий улиц того же происхождения в городе Вильнюсе больше?
					Например, в Балсяй есть собрание улиц, названных в честь божеств балтов, а в Павильнисе — в честь
					названий народов. Приглашаем познакомиться с улицами Вильнюса и их происхождением.
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Раздел городских улиц портала состоит из двух частей - настоящее и прошлое.
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					В разделе <b>настоящего</b> содержится информация обо всех улицах города. Они классифицируются по
					значению названий, по которым можно фильтровать улицы. Например, можно узнать, сколько улиц в городе
					Вильнюсе названы в честь писателей. В нем также приводится информация о названиях каждой улицы или
					ее части, которые можно найти на исторических картах (в их первоначальном виде).
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					В разделе <b>прошлого</b> содержится информация об истории улиц центральной части города Вильнюса -
					названия 5 исторических периодов и осевых линий, которые можно сопоставить между собой. Также даны
					ссылки на оригинальные карты, с которых были оцифрованы исторические названия улиц и осевые линии.
				</Typography>
			</Box>
		),
		streets_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Информация об улице предоставляется нажатием на ее строку на карте или выбором улицы из списка в
							разделе поиска. В открывшемся информационном окне доступ к соответствующим историческим
							материалам можно получить, щелкнув на интерактивный текст.
						</li>
						<li style={{ paddingLeft: 4 }}>
							Функции поиска и фильтрации улиц доступны в разделе поиска в левой части экрана.
						</li>
						<ul style={{ listStyleType: "disc" }}>
							<li>Поиск осуществляется путем ввода названия улицы в строку поиска.</li>
							<li>
								Список улиц фильтруется путем выбора класса и подкласса объекта. Чтобы удалить фильтры,
								нажмите кнопку очистки фильтров.
							</li>
						</ul>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2 }}
								component="img"
								src="https://i.ibb.co/hX0Cr4y/25-01-2023-20-01-22-REC-Adobe-Express.gif"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							Историческая информация об уличной сети за разные периоды доступна по нажатию кнопки прошлого в
							верхней части экрана.
						</li>
						<li style={{ paddingLeft: 4 }}>Год уличной сети выбирается нажатием кнопок внизу экрана.</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2, width: "60%", height: "100%", resizeMode: "contain" }}
								component="img"
								src="https://i.ibb.co/tYYZQ1X/25-01-2023-21-06-44-REC-Adobe-Express.gif"
								className="helpGif"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							Информация об улице доступна по щелчку на ее линии на карте. В открывшемся информационном окне
							доступ к соответствующим историческим материалам можно получить, щелкнув на интерактивный текст.
						</li>
					</ol>
				</Typography>
			</Box>
		),

		persons_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Раздел содержит информацию о личностях, в честь которых в городе Вильнюсе установлены памятные
					доски, скульптуры, памятники или улицы, названные в их честь. Информация включает в себя:
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Выделенные основные факты биографии личности. Факты, связанные с Вильнюсом, выделены красным.
						</li>
						<li style={{ paddingLeft: 4 }}>Описание связи с Вильнюсом;</li>
						<li style={{ paddingLeft: 4 }}>
							Ссылки на скульптуры и памятники, воздвигнутые в честь личности в Вильнюсе;
						</li>
						<li style={{ paddingLeft: 4 }}>
							Ссылки на памятные доски, установленные в честь личности в Вильнюсе;
						</li>
						<li style={{ paddingLeft: 4 }}>Направления улиц, названные в честь личности;</li>
						<li style={{ paddingLeft: 4 }}>
							Ссылку на место захоронения лица, если лицо захоронено в городе Вильнюсе и известно место
							захоронения;
						</li>
						<li style={{ paddingLeft: 4 }}>
							Важные для города Вильнюса исторические события, связанные с личностью;
						</li>
						<li style={{ paddingLeft: 4 }}>Другие личности, связанные с личностью.</li>
					</ol>
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Описания большинства личностей подготовлены на основе основного научного источника «Универсальная
					литовская энциклопедия», статьи которой подготовлены литовскими учеными, специалистами в различных
					областях и научными редакторами. «Универсальная литовская энциклопедия» доступна на сайте{" "}
					<Link href="https://www.vle.lt" target="_blank">
						www.vle.lt
					</Link>
					, ее администрирует Издательский центр науки и энциклопедий.
				</Typography>
			</Box>
		),
		persons_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Информация о личности, увековеченной в городе, доступна после выбора личности из списка в левой
							части экрана, в разделе поиска.
							<ul style={{ listStyleType: "disc" }}>
								<li>Поиск осуществляется путем ввода имени/фамилии личности в строку поиска.</li>
								<li>
									Список личностей фильтруется путем выбора профессии и деятельности личности. Чтобы удалить
									фильтры, нажмите кнопку очистки фильтров.
								</li>
							</ul>
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2 }}
								component="img"
								src="https://i.ibb.co/qpTyx9g/25-01-2023-20-08-56-REC-Adobe-Express.gif"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							В разделе Описания личности соответствующие исторические материалы доступны при нажатии на
							интерактивный текст.
						</li>
					</ol>
				</Typography>
			</Box>
		),

		foto_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					В разделе собраны старые фотографии и открытки города Вильнюса - на карте отмечено место, где они
					были сделаны, фотографии можно искать по дате их съемки, автору или покрытию карты. На данный момент
					все 183 фотографии, представленные в разделе, интегрированы из проекта «Забытый Вильнюс» 2015-2016
					гг., реализованного Библиотекой ЛАН им. Врублевских. Большинство интегрированных фотографий были
					сделаны двумя известными вильнюсскими фотографами - Яном Булгаком и Станиславом Филибертом Флерисом.
				</Typography>
			</Box>
		),

		foto_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Фотография и информация о ней доступны, нажав на символ на карте или выбрав фотографию из списка
							в левой части экрана, в разделе поиска. В открывшемся информационном окне доступ к
							соответствующим историческим материалам можно получить, щелкнув интерактивный текст.
						</li>
						<li style={{ paddingLeft: 4 }}>
							Функции поиска и фильтрации фотографий и открыток предусмотрены в разделе поиска в левой части
							экрана:
						</li>
						<ul style={{ listStyleType: "disc" }}>
							<li>Поиск осуществляется путем ввода названия фотографии в строке поиска.</li>
							<li>
								Список фотографий фильтруется по выбору автора фотографии или года съемки. Год определяется
								путем прокрутки полосы года, выбора даты начала и окончания периода. Чтобы удалить фильтры,
								нажмите кнопку очистки фильтров.
							</li>
							<li>
								Список фотографий фильтруется по объектам, видимым в покрытии карты, нажатием на кнопку
								Показывать в списке только объекты, видимые в покрытии карты.
							</li>
						</ul>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2 }}
								component="img"
								src="https://i.ibb.co/1MJLF3t/25-01-2023-20-22-41-REC-Adobe-Express.gif"
							/>
						</Grid>
					</ol>
				</Typography>
			</Box>
		),

		plaques_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Раздел содержит информацию обо всех памятных досках, памятниках, скульптурах и других художественных
					или малых архитектурных объектах города (как городских Вильнюса, так и установленных по частной
					инициативе), которые авторам проекта удалось выявить из различных открытых источников. Также
					предоставляется информация о недавно удаленных объектах. Сами объекты можно фильтровать по периоду
					установки (когда он известен), по типу объекта или по типу памяти (в память о ком объект был
					установлен).
				</Typography>
			</Box>
		),
		plaques_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Информацию о скульптуре или мемориальной доске можно найти, нажав на символ на карте или выбрав
							объект из списка в левой части экрана, в разделе поиска. В открывшемся информационном окне
							доступ к соответствующим историческим материалам можно получить, щелкнув интерактивный текст.
						</li>
						<li style={{ paddingLeft: 4 }}>
							Функции поиска и фильтрации скульптур и досок доступны в разделе поиска в левой части экрана:
						</li>
						<ul style={{ listStyleType: "disc" }}>
							<li>
								Поиск осуществляется путем ввода названия скульптуры или мемориальной доски в строку поиска.
							</li>
							<li>
								Список скульптур и мемориальных досок фильтруется по типу объекта, типу памятника или времени
								установки. Чтобы удалить фильтры, вам нужно нажать кнопку очистки фильтров.
							</li>
							<li>
								Список скульптур и мемориальных досок фильтруется по объектам, видимым в покрытии карты,
								нажатием кнопки «Показать в списке только объекты, видимые в покрытии карты».
							</li>
						</ul>
						<li>
							Условные обозначения для изображения скульптур и мемориальных досок изменены в разделе
							изображения в левой части экрана. Выбираемое представление по объекту или типу памяти.
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2 }}
								component="img"
								src="https://i.ibb.co/8cskrkx/25-01-2023-20-34-42-REC-Adobe-Express.gif"
							/>
						</Grid>
					</ol>
				</Typography>
			</Box>
		),

		periods_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Вы когда-нибудь хотели узнать, как менялся Вильнюс в разные периоды? Если да, то вы, вероятно,
					пытались это сделать, просматривая старые исторические карты, но часто бывает не так просто увидеть
					изменения, которые произошли в городе, пролистывая их. Разные исторические карты выполнены в разном
					масштабе, изображенные на них объекты обобщаются по-разному (одни и те же объекты представлены с
					разной детализацией). Мало того, чем старше карта, тем она менее точна. Другими словами, даже после
					наложения одной карты на другую часто бывает трудно определить, является ли один и тот же
					интересующий объект, изображенный на обеих картах, одинаковым или уже изменился. Практически всегда
					старые карты даже визуализируются в другом дизайне!
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Так какое же может быть решение, которое позволило бы даже людям, не разбирающимся в картографии,
					просто изучить изменения, происходившие в Вильнюсе? Это можно сделать путем реконструкции
					исторических топографических карт (или просто - топографий), которые представлены в разделе "Город в
					отдельные исторические периоды".
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Как это делается? Мы бы выделили несколько этапов:
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Эксперты ЗАО "Вильняус планас" отбирали исторические карты по нескольким важным критериям:
					детализация и представление различных исторических периодов Вильнюса:
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							На плане К. Грунерта 1808 года реально показан Вильнюс до царского периода;
						</li>
						<li style={{ paddingLeft: 4 }}>План 1845 г. – середины царского периода;</li>
						<li style={{ paddingLeft: 4 }}>1911 года – конца царского периода;</li>
						<li style={{ paddingLeft: 4 }}>1938 года – конца межвоенного периода;</li>
						<li style={{ paddingLeft: 4 }}>1977 года – город позднесоветского времени.</li>
					</ol>
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Все перечисленные топографические карты были оцифрованы - отсканированы, сориентированы в
					современных системах координат, обрезаны (такие подробные карты состоят из множества листов, их поля
					накладываются друг на друга и закрывают важные объекты), очищаются, корректируются цвета и яркость.
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					На заключительном этапе картографирования топографии были реконструированы. Каждый объект,
					представленный на старой топографической карте, картограф сравнивал с точными цифровыми данными того
					времени и оценивал - историческая карта изображает тот же объект, что и сейчас, или изменился. Если
					он такой же, как сейчас, то контур объекта (например, здания) передается на реконструкцию по
					цифровым данным того времени. Если предполагается, что на исторической карте изображен другой
					объект, то его контур с карты точно воспроизводится, включая его реконструкцию. Таким способом
					реконструировали все отобранные карты, восстанавливая их от позднейших к наиболее старым, постоянно
					оценивая отдельные географические объекты и перенося их из предшествующего периода или рисуя заново
					с исторической карты.
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					<b>Точность и качество данных</b>. Эти реконструкции облегчают пользователю сравнение данных,
					представленных на разных исторических картах, но они могут содержать неточности из-за описанного
					выше процесса реконструкции. Предлагаем критически отнестись к представленным данным, поскольку
					неточности встречаются даже в использованных оригинальных исторических топографических картах - если
					автор изобразил на карте объект, это не значит, что он действительно был таким (особенно это
					касается карт 19 века).
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					На данный момент реконструирована только центральная часть города Вильнюса, общая для выбранных
					карт.
				</Typography>
			</Box>
		),
		periods_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>Информация о карте отображается в правом верхнем углу экрана.</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2, width: "60%", height: "100%", resizeMode: "contain" }}
								component="img"
								src="https://i.ibb.co/pWh75Hk/25-01-2023-20-41-12-REC-Adobe-Express.gif"
								className="helpGif"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							Соответствующие исторические материалы доступны, нажав на интерактивный текст.
						</li>
						<li style={{ paddingLeft: 4 }}>
							Режим просмотра карты изменяется нажатием кнопки в верхней левой части экрана. Доступны три
							режима:
						</li>
						<ul style={{ listStyleType: "disc" }}>
							<li>Режим временной шкалы: отображает одну карту, выбранную на временной шкале.</li>
							<li>
								Режим полосы прокрутки: разделяет экран на две части, отображая две разные выбранные карты.
								Они сравниваются путем прокрутки полосы.
							</li>
							<li>
								Двухоконный режим: разделяет экран на две части, показывая две разные выбранные карты,
								объединяя их масштаб и покрытие. Карты сравниваются, управляя одной из них.
							</li>
						</ul>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2 }}
								component="img"
								src="https://i.ibb.co/rftYtYd/25-01-2023-20-46-55-REC-Adobe-Express.gif"
							/>
						</Grid>
					</ol>
				</Typography>
			</Box>
		),

		events_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Раздел содержит информацию о наиболее важных для города Вильнюса исторических событиях, которые
					историки включили в «Календарь города Вильнюса», созданный в рамках Вильнюсской программы культуры
					памяти. События сгруппированы по периодам истории Вильнюса, связаны с родственными личностями,
					фотографиями, памятниками и картами.
				</Typography>
			</Box>
		),
		events_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Лента исторический событий управляется прокруткой окна браузера вверх или вниз.
						</li>
						<li style={{ paddingLeft: 4 }}>
							Дополнительную информацию об историческом событии можно увидеть, нажав на текст Подробнее... под
							фотографией и названием события. Соответствующие исторические материалы доступны, нажав на
							интерактивный текст.
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2, width: "30%", height: "100%", resizeMode: "contain" }}
								component="img"
								src="https://i.ibb.co/jZfR5Kv/25-01-2023-20-59-25-REC-Adobe-Express.gif"
								className="helpGifTall"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							Исторические события можно отфильтровать и выбрать по историческим периодам, нажав на ленту
							исторических событий в левой части экрана.
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2, width: "30%", height: "100%", resizeMode: "contain" }}
								component="img"
								src="https://i.ibb.co/zH7nTpc/25-01-2023-20-54-44-REC-Adobe-Express.gif"
								className="helpGifTall"
							/>
						</Grid>
					</ol>
				</Typography>
			</Box>
		),

		contacts: (
			<Box sx={{ mt: 8, position: "relative" }}>
				<Grid container direction="column" justifyContent="center" alignItems="center">
					<Box
						sx={{
							width: 280,
						}}
						component="img"
						src={box}
					/>
					<Typography sx={{ maxWidth: 280, px: 2, position: "relative", mt: -13, textAlign: "center" }}>
						У вас есть вопросы или предложения? Свяжитесь!
					</Typography>
					<Box
						sx={{
							width: 300,
							mt: -2,
						}}
						component="img"
						src={map}
					/>
					<Box
						sx={{
							width: 30,
							mt: -24,
						}}
						component="img"
						src={pin}
					/>
					<Typography sx={{ color: "white", fontSize: "16px", fontWeight: 400, mt: 20 }}>
						Электронная почта:
					</Typography>
					<Typography sx={{ color: "white", fontSize: "18px", fontWeight: 500, mt: 1 }}>
						vilniusdnr@vplanas.lt
					</Typography>
				</Grid>
			</Box>
		),
	},
	pl: {
		////////////////////
		about: (
			<Box sx={{ mr: 4 }}>
				<Box sx={{ mx: "2%" }}>
					<Grid
						sx={{ display: "flex", alignItems: "stretch" }}
						container
						direction="row"
						justifyContent="space-between"
						alignItems="flex-start"
					>
						<Box
							sx={{ backgroundColor: "#D72E31", width: "40%", minHeight: "60vh", mt: "6%", p: 4 }}
							className="aboutBox"
						>
							<Typography
								sx={{ color: "white", fontSize: "24px", fontWeight: 600, mt: 2, textAlign: "center" }}
							>
								Projekt DNA Wilna
							</Typography>
							<Typography
								sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 2, textAlign: "center" }}
							>
								Projekt DNA Wilna Portal DNA Wilna to wirtualna internetowa brama do historii Wilna. Tutaj
								znajdziesz historyczne mapy miasta, które kodują przeszłość Wilna, oraz narzędzia, które
								pomogą Ci rozwikłać ten splątany kod. Informacje prezentowane w różnych działach portalu
								pomogą lepiej poznać Wilno – znajdziesz w nim rekonstrukcje topografii historycznych,
								historyczne fotografie, współczesne i historyczne ulice, postaci uwiecznione w mieście, ich
								tablice memorialne oraz najważniejsze wydarzenia historyczne. Wszystkie te informacje łączą
								się ze sobą, tworząc wieloaspektowy, ale integralny i niepowtarzalny kod Wilna. Kod DNA Wilna.
							</Typography>
							<Typography
								sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1, textAlign: "center" }}
							>
								Symboliczne jest otwarcie portalu „DNA Wilna” w związku z obchodami 700-lecia Wilna. Jest to
								prezentem dla miasta Wilna, mieszkańców Wilna, gości miasta oraz wszystkich tych, którzy są
								ciekawi Wilna i jego barwnej historii.
							</Typography>

							<Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
								<Box
									sx={{
										width: "40%",
										mt: 3,
									}}
									component="img"
									src={Y700}
								/>
							</Grid>
						</Box>

						<Box
							sx={{ backgroundColor: "#EBEBEB", width: "40%", minHeight: "60vh", mt: "6%", p: 4 }}
							className="aboutBox"
						>
							<Typography
								sx={{ color: "black", fontSize: "24px", fontWeight: 600, mt: 2, textAlign: "center" }}
							>
								Twórcy
							</Typography>
							<Typography
								sx={{ color: "black", fontSize: "14px", fontWeight: 400, mt: 1, textAlign: "center" }}
							>
								Projekt jest realizowany przez Samorząd miasta Wilna i firmę UAB "ID Vilnius".
							</Typography>
							<Grid container direction="row" justifyContent="center" alignItems="flex-end">
								<Box
									sx={{
										width: "50%",
										mt: 3,
										cursor: "pointer",
									}}
									component="img"
									src={Vilnius_vp}
									onClick={() => {
										window.open("https://www.idvilnius.lt/", "_blank")
									}}
								/>
							</Grid>
							<Typography
								sx={{ color: "black", fontSize: "24px", fontWeight: 600, mt: 4, textAlign: "center" }}
							>
								Partnerzy
							</Typography>
							<Typography
								sx={{ color: "black", fontSize: "14px", fontWeight: 400, mt: 1, textAlign: "center" }}
							>
								Centrum Wydawnictw Naukowych i Encyklopedycznych oraz Biblioteka im. Wróblewskiech Litewskiej
								Akademii Nauk dostarczyły materiałów do stworzenia tego projektu.
							</Typography>
							<Grid container direction="row" justifyContent="center" alignItems="flex-end">
								<Box
									sx={{
										width: "50%",
										mt: 3,
										cursor: "pointer",
									}}
									component="img"
									src={LMAVB}
									onClick={() => {
										window.open("http://www.mab.lt/", "_blank")
									}}
								/>
								<Box
									sx={{
										width: "50%",
										mt: 3,
										cursor: "pointer",
									}}
									component="img"
									src={MELC_DNR}
									onClick={() => {
										window.open("https://www.melc.lt/lt/", "_blank")
									}}
								/>
							</Grid>
						</Box>
					</Grid>
				</Box>
			</Box>
		),

		maps_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Czy kiedykolwiek szukałeś starych planów miasta Wilna? Nie szukaj dalej, ponieważ w sekcji „Plany i
					mapy historyczne” portalu „DNA Wilna” znajdziesz zintegrowane plany historyczne i mapy Wilna z
					różnych źródeł. Wszystkie są zdigitalizowane i zorientowane w nowoczesnym układzie współrzędnych -
					mapy historyczne można porównać ze współczesnymi. Obecnie istniejące mapy dzielą się na cztery
					grupy:
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ul>
						<li>
							Plany topograficzne to najbardziej szczegółowe mapy historyczne dostępne w dużej skali (1:5000 i
							większe), na których naturalne i stworzone przez człowieka cechy powierzchni ziemi są dokładnie
							przedstawione za pomocą konwencjonalnych znaków. Ze względu na wysoki poziom szczegółowości mapy
							te składają się zwykle z różnych arkuszy.
						</li>
						<li>
							Rekonstrukcje historycznych topografii są odtwarzanymi i korygowanymi planami topograficznymi.
							Więcej szczegółów na temat rekonstrukcji można znaleźć w dziale{" "}
							<b>„Miasto w określonych okresach historycznych”</b>.
						</li>
						<li>
							Mapy ortofotograficzne to mapy opracowane na podstawie zdjęć lotniczych powierzchni ziemi
							wykonanych z samolotów.
						</li>
						<li>
							Mapy Biblioteki LAN im. Wróblewskich z lat 2015-2016. W ramach projektu „Zapomniane Wilno”
							biblioteki Litewskiej Akademii Nauk im. Wróblewskich zdigitalizowano 49 map. Mapy te powstały w
							XIX i XX wieku, ich skala waha się od 1:8 000 do 1:40 000, a nazwy map przedstawiono w ich
							oryginalnej formie.
						</li>
					</ul>
				</Typography>
			</Box>
		),
		maps_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Plan lub mapę historyczną otwiera się naciskając przycisk na dole ekranu, wybierając żądaną
							grupę i mapę.
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2, width: "60%", height: "100%", resizeMode: "contain" }}
								component="img"
								src="https://i.ibb.co/hFtkgz3/25-01-2023-19-43-51-REC-Adobe-Express.gif"
								className="helpGif"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							Przezroczystość planu historycznego lub mapy można zmienić przesuwając suwak w lewym górnym rogu
							ekranu.
						</li>
						<li style={{ paddingLeft: 4 }}>
							Tryb przeglądania planów historycznych lub map zmienia się poprzez naciśnięcie przycisku
							znajdującego się w lewej górnej części ekranu. Dostępne są trzy tryby:
						</li>
						<ul style={{ listStyleType: "disc" }}>
							<li>Tryb przeglądania: Wyświetlana jest jedna wybrana karta.</li>
							<li>
								Tryb paska przewijania: dzieli ekran na dwie części, pokazując dwie różne wybrane karty.
								Porównuje się je przewijając pasek.
							</li>
							<li>
								Tryb podwójnego okna: Dzieli ekran na dwie części, pokazując dwie różne wybrane mapy, łącząc
								ich skalę i zasięg. Karty porównuje się, kierując jedną z nich.
							</li>
						</ul>
					</ol>
					<Grid container direction="row" justifyContent="center" alignItems="center">
						<Box
							sx={{ my: 2, mr: 2 }}
							component="img"
							src="https://i.ibb.co/7rpDBBz/25-01-2023-19-53-24-REC-Adobe-Express.gif"
						/>
					</Grid>
				</Typography>
			</Box>
		),

		streets_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Wielu mieszkańców Wilna zapewne zauważyło, że na Zwierzyńcu jest wiele ulic nazwanych imionami
					zwierząt. Ale czy wiesz, że takich nazw ulic o tym samym pochodzeniu jest więcej w Wilnie? Na
					przykład w Balsiach istnieje zbiór ulic nazwanych imionami bóstw Bałtów, a w Pawilnisie - imionami
					ludów. Zapraszamy do zapoznania się z ulicami Wilna i ich genezą.
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Sekcja ulic miasta portalu składa się z dwóch części - teraźniejszości i przeszłości.
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					<b>Ta sekcja</b> zawiera informacje o wszystkich ulicach miasta. Są one klasyfikowane według
					znaczenia nazw, według których można filtrować ulice. Możesz na przykład dowiedzieć się, ile ulic w
					Wilnie nosi imiona pisarzy. Zawiera również informacje o nazwach poszczególnych ulic lub ich
					fragmentów, które można znaleźć na mapach historycznych (w ich oryginalnej formie).
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					<b>Dział przeszłości</b> zawiera informacje o historii ulic centralnej części Wilna - nazwy 5
					okresów historycznych i linii środkowych, które można ze sobą porównywać. Udostępniono również łącza
					do oryginalnych map, z których zdigitalizowano historyczne nazwy ulic i linie środkowe.
				</Typography>
			</Box>
		),
		streets_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Informacje o ulicy można uzyskać, klikając jej linię na mapie lub wybierając ulicę z listy w
							sekcji wyszukiwania. W oknie informacyjnym, które zostanie otwarte, dostęp do odpowiednich
							materiałów historycznych można uzyskać, klikając na interaktywny tekst.
						</li>
						<li style={{ paddingLeft: 4 }}>
							Funkcje wyszukiwania ulic i filtrowania są dostępne w sekcji wyszukiwania po lewej stronie
							ekranu.
						</li>
						<ul style={{ listStyleType: "disc" }}>
							<li>Wyszukiwanie odbywa się poprzez wpisanie nazwy ulicy w pasku wyszukiwania.</li>
							<li>
								Lista ulic jest filtrowana poprzez wybór klasy i podklasy obiektu. Aby usunąć filtry, kliknij
								przycisk wyczyść filtry.
							</li>
						</ul>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2 }}
								component="img"
								src="https://i.ibb.co/hX0Cr4y/25-01-2023-20-01-22-REC-Adobe-Express.gif"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							Historyczne informacje o sieci ulic z różnych okresów są dostępne po naciśnięciu przycisku
							przeszłości u góry ekranu.
						</li>
						<li style={{ paddingLeft: 4 }}>Rok sieci ulic wybiera się za pomocą przycisków u dołu ekranu.</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2, width: "60%", height: "100%", resizeMode: "contain" }}
								component="img"
								src="https://i.ibb.co/tYYZQ1X/25-01-2023-21-06-44-REC-Adobe-Express.gif"
								className="helpGif"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							Informacje o ulicy są dostępne po kliknięciu jej linii na mapie. W oknie informacyjnym, które
							zostanie otwarte, dostęp do odpowiednich materiałów historycznych można uzyskać, klikając na
							interaktywny tekst.
						</li>
					</ol>
				</Typography>
			</Box>
		),

		persons_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					W tym dziale znajdują się informacje o osobowościach, ku czci których w Wilnie umieszczono tablice
					pamiątkowe, rzeźby, pomniki lub ulice nazwane ich imionami. Informacje obejmują:
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Podkreślono główne fakty z biografii osobowości. Fakty dotyczące Wilna zaznaczono na czerwono.
						</li>
						<li style={{ paddingLeft: 4 }}>Opis połączenia z Wilnem;</li>
						<li style={{ paddingLeft: 4 }}>
							Linki do rzeźb i pomników wzniesionych ku czci osobowości w Wilnie;
						</li>
						<li style={{ paddingLeft: 4 }}>
							Linki do tablic pamiątkowych wzniesionych ku czci osobowości w Wilnie;
						</li>
						<li style={{ paddingLeft: 4 }}>Kierunki ulic nazwane imieniem osobowości;</li>
						<li style={{ paddingLeft: 4 }}>
							wzmianka o miejscu pochówku osobowości, jeżeli jest ona pochowana na terenie miasta Wilna i
							miejsce pochówku jest znane;
						</li>
						<li style={{ paddingLeft: 4 }}>
							Ważne wydarzenia historyczne dla miasta Wilna związane z osobowością;
						</li>
						<li style={{ paddingLeft: 4 }}>Inne osobowości związane z osobowością.</li>
					</ol>
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Opisy większości osobowości zostały opracowane na podstawie głównego źródła naukowego „Litewska
					encyklopedia powszechna”, której artykuły przygotowywali litewscy naukowcy, specjaliści z różnych
					dziedzin i redaktorzy naukowi. „Litewska encyklopedia powszechna” jest dostępna na stronie{" "}
					<Link href="https://www.vle.lt" target="_blank">
						www.vle.lt
					</Link>{" "}
					i jest administrowana przez Centrum Wydawnicze Nauki i Encyklopedii.
				</Typography>
			</Box>
		),
		persons_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Informacje o osobowości uwiecznionej w mieście dostępne są po wybraniu osoby z listy po lewej
							stronie ekranu, w sekcji wyszukiwania.
							<ul style={{ listStyleType: "disc" }}>
								<li>
									Wyszukiwanie odbywa się poprzez wpisanie imienia/nazwiska osobowości w pasku wyszukiwania.
								</li>
								<li>
									Lista osobowości jest filtrowana poprzez wybór zawodu i działalności osoby. Aby usunąć
									filtry, kliknij przycisk wyczyść filtry.
								</li>
							</ul>
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2 }}
								component="img"
								src="https://i.ibb.co/qpTyx9g/25-01-2023-20-08-56-REC-Adobe-Express.gif"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							W sekcji Opisy osobowości odpowiednie materiały historyczne są dostępne po kliknięciu
							interaktywnego tekstu.
						</li>
					</ol>
				</Typography>
			</Box>
		),

		foto_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Dział zawiera stare fotografie i pocztówki miasta Wilna - miejsce ich wykonania jest zaznaczone na
					mapie, zdjęcia można wyszukiwać po dacie wykonania, autorze lub pokryciu mapy. W tej chwili
					wszystkie 183 zdjęcia prezentowane w dziale są zintegrowane z projektu „Zapomniane Wilno” z lat
					2015-2016, realizowanego przez Bibliotekę LAN im. Wróblewskich. Większość zintegrowanych fotografii
					wykonała dwójka znanych wileńskich fotografików - Jan Bułhak i Stanisław Filibert Fleris.
				</Typography>
			</Box>
		),
		foto_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Zdjęcie i informacje o nim dostępne są po kliknięciu na symbol na mapie lub wybraniu zdjęcia z
							listy po lewej stronie ekranu w sekcji wyszukiwania. W oknie informacyjnym, które zostanie
							otwarte, można uzyskać dostęp do odpowiednich materiałów historycznych, klikając interaktywny
							tekst.
						</li>
						<li style={{ paddingLeft: 4 }}>
							Funkcje wyszukiwania i filtrowania zdjęć i pocztówek są dostępne w sekcji wyszukiwania po lewej
							stronie ekranu:
						</li>
						<ul style={{ listStyleType: "disc" }}>
							<li>Wyszukiwanie odbywa się poprzez wpisanie nazwy zdjęcia w pasku wyszukiwania.</li>
							<li>
								Lista zdjęć jest filtrowana według wyboru autora zdjęcia lub roku wykonania. Rok określa się,
								przewijając pasek roku, wybierając datę początkową i końcową okresu. Aby usunąć filtry,
								kliknij przycisk wyczyść filtry.
							</li>
							<li>
								Listę zdjęć filtruje się według obiektów widocznych na pokryciu mapy, klikając przycisk Pokaż
								na liście tylko obiekty widoczne na pokryciu mapy.
							</li>
						</ul>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2 }}
								component="img"
								src="https://i.ibb.co/1MJLF3t/25-01-2023-20-22-41-REC-Adobe-Express.gif"
							/>
						</Grid>
					</ol>
				</Typography>
			</Box>
		),

		plaques_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Dział zawiera informacje o wszystkich tablicach pamiątkowych, pomnikach, rzeźbach i innych obiektach
					artystycznych lub małej architektury miasta (zarówno miasta Wilna, jak i zainstalowanych z
					inicjatywy prywatnej), które autorom projektu udało się zidentyfikować z różnych otwartych źródeł.
					Dostępne są również informacje o ostatnio usuniętych obiektach. Same obiekty można filtrować według
					okresu instalacji (jeśli jest znany), typu obiektu lub typu pamięci (w kogo pamięci obiekt został
					zainstalowany).
				</Typography>
			</Box>
		),
		plaques_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Informacje o rzeźbie lub tablicy można znaleźć klikając na symbol na mapie lub wybierając obiekt
							z listy po lewej stronie ekranu w sekcji wyszukiwania. W oknie informacyjnym, które zostanie
							otwarte, można uzyskać dostęp do odpowiednich materiałów historycznych, klikając interaktywny
							tekst.
						</li>
						<li style={{ paddingLeft: 4 }}>
							Funkcje wyszukiwania i filtrowania rzeźb i tablic są dostępne w sekcji wyszukiwania po lewej
							stronie ekranu:
						</li>
						<ul style={{ listStyleType: "disc" }}>
							<li>
								Wyszukiwanie odbywa się poprzez wpisanie w wyszukiwarkę nazwy rzeźby lub tablicy pamiątkowej.
							</li>
							<li>
								Lista rzeźb i tablic jest filtrowana według typu obiektu, rodzaju pomnika lub czasu montażu.
								Aby usunąć filtry, należy kliknąć przycisk wyczyść filtry.
							</li>
							<li>
								Listę rzeźb i tablic można filtrować według obiektów widocznych w pokryciu mapowym, klikając
								przycisk „Pokaż na liście tylko obiekty widoczne w pokryciu mapowym”.
							</li>
						</ul>
						<li>
							W sekcji obrazów po lewej stronie ekranu zmieniono konwencje przedstawiania rzeźb i tablic.
							Wybieralna reprezentacja według typu obiektu lub pamięci.
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2 }}
								component="img"
								src="https://i.ibb.co/8cskrkx/25-01-2023-20-34-42-REC-Adobe-Express.gif"
							/>
						</Grid>
					</ol>
				</Typography>
			</Box>
		),

		periods_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Czy kiedykolwiek chciałeś wiedzieć, jak zmieniało się Wilno w różnych okresach? Jeśli tak,
					prawdopodobnie próbowałeś tego, patrząc na stare mapy historyczne, ale często nie jest łatwo
					zobaczyć zmiany, które zaszły w mieście, przeglądając je. Różne mapy historyczne wykonywane są w
					różnych skalach, przedstawiane na nich obiekty są w różny sposób uogólniane (te same obiekty
					przedstawiane są z różnymi detalami). Mało tego, im starsza mapa, tym mniej jest ona dokładna.
					Innymi słowami, nawet po nałożeniu jednej mapy na drugą, często trudno jest określić, czy ten sam
					obiekt zainteresowania przedstawiony na obu mapach jest taki sam, czy już się zmienił. Prawie zawsze
					stare karty są renderowane w innym stylu!
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Jakie więc mogłoby być rozwiązanie, które pozwoliłoby nawet osobom nieobeznanym z kartografią po
					prostu przestudiować zmiany, jakie zaszły w Wilnie? Można tego dokonać poprzez rekonstrukcję
					historycznych map topograficznych (lub po prostu topografii), które prezentowane są w dziale „Miasta
					w określonych okresach historycznych”.
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Jak tego dokonać? Wyróżnilibyśmy kilka etapów:
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Eksperci UAB „ID Vilnius” wybrali mapy historyczne według kilku ważnych kryteriów:
					uszczegółowienie i przedstawienie różnych okresów historycznych Wilna:
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Plan K. Grunerta z 1808 r. rzeczywiście przedstawia Wilno przed okresem carskim;
						</li>
						<li style={{ paddingLeft: 4 }}>Plan z 1845 r. – połowa okresu carskiego;</li>
						<li style={{ paddingLeft: 4 }}>1911 r. - koniec okresu carskiego;</li>
						<li style={{ paddingLeft: 4 }}>1938 r. - koniec okresu międzywojennego;</li>
						<li style={{ paddingLeft: 4 }}>1977 r. - miasto późnej epoki sowieckiej.</li>
					</ol>
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Wszystkie powyższe mapy topograficzne zostały zdigitalizowane - zeskanowane, zorientowane w
					nowoczesnych układach współrzędnych, wykadrowane (tak szczegółowe mapy składają się z wielu arkuszy,
					ich pola nakładają się na siebie i obejmują ważne obiekty), wyczyszczone, poprawione kolory i
					jasność.
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					W końcowym etapie mapowania zrekonstruowano topografię. Każdy obiekt przedstawiony na starej mapie
					topograficznej został przez kartografa porównany z dokładnymi danymi cyfrowymi z tamtego czasu i
					oceniony – czy mapa historyczna przedstawia ten sam obiekt, jaki jest obecnie lub uległ on zmianie.
					Jeśli jest taki sam jak teraz, wówczas kontur obiektu (na przykład budynku) jest przekazywany do
					rekonstrukcji zgodnie z danymi cyfrowymi z tamtego czasu. Jeżeli przyjąć, że na mapie historycznej
					przedstawiony jest inny obiekt, to wiernie odwzorowuje się jego zarys z mapy, łącznie z jego
					rekonstrukcją. W ten sposób rekonstruowano wszystkie wybrane mapy, odtwarzając je od najnowszych do
					najstarszych, stale oceniając poszczególne obiekty geograficzne i przenosząc je z poprzedniego
					okresu lub rysując na nowo z mapy historycznej.
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					<b>Dokładność i jakość danych</b>. Rekonstrukcje te ułatwiają użytkownikowi porównywanie danych
					prezentowanych na różnych mapach historycznych, ale mogą zawierać nieścisłości ze względu na opisany
					powyżej proces rekonstrukcji. Sugerujemy krytyczne spojrzenie na prezentowane dane, ponieważ
					nieścisłości znajdują się nawet w użytych oryginalnych historycznych mapach topograficznych – jeśli
					autor przedstawił obiekt na mapie, nie oznacza to, że tak było naprawdę (zwłaszcza w przypadku map z
					XIX wieku).
				</Typography>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Na chwilę obecną zrekonstruowano jedynie centralną część Wilna, wspólną dla wybranych map.
				</Typography>
			</Box>
		),
		periods_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Okres historyczny wybiera się, naciskając przyciski na osi czasu u dołu ekranu
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2, width: "60%", height: "100%", resizeMode: "contain" }}
								component="img"
								src="https://i.ibb.co/pWh75Hk/25-01-2023-20-41-12-REC-Adobe-Express.gif"
								className="helpGif"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							Informacje o mapie są wyświetlane w prawym górnym rogu ekranu. Odpowiedni materiał historyczny
							jest dostępny po kliknięciu interaktywnego tekstu.
						</li>
						<li style={{ paddingLeft: 4 }}>
							Tryb widoku mapy zmienia się, naciskając przycisk w lewym górnym rogu ekranu. Dostępne są trzy
							tryby:
						</li>
						<ul style={{ listStyleType: "disc" }}>
							<li>Tryb osi czasu: Wyświetla jedną kartę wybraną na osi czasu.</li>
							<li>
								Tryb paska przewijania: dzieli ekran na dwie części, wyświetlając dwie różne wybrane karty.
								Porównuje się je przewijając pasek.
							</li>
							<li>
								Podwójny widok: Dzieli ekran na dwie części, pokazując dwie różne wybrane mapy, łącząc ich
								skalę i zasięg. Karty porównuje się, kierując jedną z nich.
							</li>
						</ul>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2 }}
								component="img"
								src="https://i.ibb.co/rftYtYd/25-01-2023-20-46-55-REC-Adobe-Express.gif"
							/>
						</Grid>
					</ol>
				</Typography>
			</Box>
		),

		events_info: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }}>
					Dział zawiera informacje o najważniejszych wydarzeniach historycznych dla miasta Wilna, które
					historycy umieszczają w „Kalendarzu Miasta Wilna”, tworzonym w ramach Wileńskiego Programu Kultury
					Pamięci. Wydarzenia pogrupowane są według okresów historii Wilna, związanych z osobowościami
					powiązanymi, fotografiami, pomnikami i mapami.
				</Typography>
			</Box>
		),
		events_how: (
			<Box>
				<Typography sx={{ color: "white", fontSize: "14px", fontWeight: 400, mt: 1 }} component={"div"}>
					<ol>
						<li style={{ paddingLeft: 4 }}>
							Kanałem zdarzeń historycznych można sterować, przewijając okno przeglądarki w górę lub w dół.
						</li>
						<li style={{ paddingLeft: 4 }}>
							Dodatkowe informacje o wydarzeniu historycznym można zobaczyć klikając na tekst Więcej... pod
							zdjęciem i nazwą wydarzenia. Odpowiedni materiał historyczny jest dostępny po kliknięciu
							interaktywnego tekstu.
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2, width: "30%", height: "100%", resizeMode: "contain" }}
								component="img"
								src="https://i.ibb.co/jZfR5Kv/25-01-2023-20-59-25-REC-Adobe-Express.gif"
								className="helpGifTall"
							/>
						</Grid>
						<li style={{ paddingLeft: 4 }}>
							Wydarzenia historyczne można filtrować i wybierać według okresu historycznego, klikając kanał
							wydarzeń historycznych po lewej stronie ekranu.
						</li>
						<Grid container direction="row" justifyContent="center" alignItems="center">
							<Box
								sx={{ my: 2, mr: 2, width: "30%", height: "100%", resizeMode: "contain" }}
								component="img"
								src="https://i.ibb.co/zH7nTpc/25-01-2023-20-54-44-REC-Adobe-Express.gif"
								className="helpGifTall"
							/>
						</Grid>
					</ol>
				</Typography>
			</Box>
		),

		contacts: (
			<Box sx={{ mt: 8, position: "relative" }}>
				<Grid container direction="column" justifyContent="center" alignItems="center">
					<Box
						sx={{
							width: 280,
						}}
						component="img"
						src={box}
					/>
					<Typography sx={{ maxWidth: 280, px: 2, position: "relative", mt: -13, textAlign: "center" }}>
						Masz pytania lub sugestie? Prosimy o kontakt!
					</Typography>
					<Box
						sx={{
							width: 300,
							mt: -2,
						}}
						component="img"
						src={map}
					/>
					<Box
						sx={{
							width: 30,
							mt: -24,
						}}
						component="img"
						src={pin}
					/>
					<Typography sx={{ color: "white", fontSize: "16px", fontWeight: 400, mt: 20 }}>
						Poczta elektroniczna:
					</Typography>
					<Typography sx={{ color: "white", fontSize: "18px", fontWeight: 500, mt: 1 }}>
						vilniusdnr@vplanas.lt
					</Typography>
				</Grid>
			</Box>
		),
	},
}

export const secondLevelTitles = {
	lt: [
		["Istoriniai planai ir žemėlapiai", "maps_info", "maps_how"],
		["Miesto gatvės", "streets_info", "streets_how"],
		["Mieste įamžintos asmenybės", "persons_info", "persons_how"],
		["Senosios fotografijos ir atvirutės", "foto_info", "foto_how"],
		["Skulptūros ir atminimo lentos", "plaques_info", "plaques_how"],
		["Miestas atskirais istoriniais periodais", "periods_info", "periods_how"],
		["Istoriniai įvykiai", "events_info", "events_how"],
	],
	en: [
		["Historical plans and maps", "maps_info", "maps_how"],
		["Streets of the city", "streets_info", "streets_how"],
		["The personalities immortalised in the city", "persons_info", "persons_how"],
		["Old photos and postcards", "foto_info", "foto_how"],
		["Sculptures and commemorative plaques", "plaques_info", "plaques_how"],
		["The city during the individual historical periods", "periods_info", "periods_how"],
		["Historical events", "events_info", "events_how"],
	],
	ru: [
		["Исторические планы и карты", "maps_info", "maps_how"],
		["Улицы города", "streets_info", "streets_how"],
		["Личности, увековеченные в городе", "persons_info", "persons_how"],
		["Старые фотографии и открытки", "foto_info", "foto_how"],
		["Скульптуры и памятные доски", "plaques_info", "plaques_how"],
		["Город в отдельные исторические периоды", "periods_info", "periods_how"],
		["Исторические события", "events_info", "events_how"],
	],
	pl: [
		["Plany i mapy historyczne", "maps_info", "maps_how"],
		["Ulice miasta", "streets_info", "streets_how"],
		["Osobowości uwiecznione w mieście", "persons_info", "persons_how"],
		["Stare fotografie i pocztówki", "foto_info", "foto_how"],
		["Rzeźby i tablice pamiątkowe", "plaques_info", "plaques_how"],
		["Miasto w poszczególnych okresach historycznych", "periods_info", "periods_how"],
		["Wydarzenia historyczne", "events_info", "events_how"],
	],
}
